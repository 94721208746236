
import Login from '../pages/unauthorized/login'
import SignUp from '../pages/unauthorized/signup'
import ActivateAccount from '../pages/unauthorized/activate'
import ForgotPassword from '../pages/unauthorized/forgotPassword'
import ResetPassword from '../pages/unauthorized/resetPassword'
import { Switch, Route, Redirect } from 'react-router-dom';
import React from 'react';



class UnAuthorized extends React.Component {
    render() {
        return (
                    <div>
                        <Switch>
                            <Route exact path={`${this.props.match.path}/register`} component={SignUp} />
                            <Route exact path={`${this.props.match.path}/activate`} component={ActivateAccount} />
                            <Route exact path={`${this.props.match.path}/login`} component={Login} />
                            <Route exact path={`${this.props.match.path}/forgot`} component={ForgotPassword} />
                            <Route exact path={`${this.props.match.path}/reset/:resetID`} component={ResetPassword} />
                            <Redirect to="/auth/login" />
                        </Switch>
                    </div>
        )
    }
}

export default UnAuthorized;
