import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
// import ArrowRed from '../../assets/icons/arrow_right_red.svg'
// import { Card } from '../uiComponents/card'
import { HighlightedText } from '../uiComponents/highlightedText';
// import { Button } from '../uiComponents/button'
/* imported images*/
import VideoIcon from "../../assets/icons/videos.svg";
// import SubscriberIcon from "../../assets/icons/suscriber_white.svg";
import SettingsIcon from "../../assets/icons/settings.svg";
// import SubscriberImage from "../../assets/images/course.svg"
import FeedbackIcon from "../../assets/icons/feedback_white.svg"
import UnAuthorizedUserIcon from "../../assets/icons/profile-user_white.svg"
import { getAuthToken } from '../../utils/storage/auth';

class GeneralSideBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            authenticated: Boolean(getAuthToken()),

        }
    }

    render() {
        return (
            <SideBar>
                <HighlightedText size="30px" className="resp__bo center-text" color="#fff" margin="0.5em 0">
                    Skideo
                </HighlightedText>

                <div className="topDiv">

                    <NavLink exact
                        activeClassName="active"
                        to="/home"
                        isActive={(match, location) => {
                            return location.pathname.includes(`/home`)
                                || location.pathname.includes(`/topic`)
                        }}
                        data-testid="home"
                    >
                        <img src={VideoIcon} alt="icon" width="20px" height="20px" />
                        <HighlightedText size="20px" textAlign="center" color="#fff" margin="1em" >
                            Home
                        </HighlightedText>

                    </NavLink>



                    {this.state.authenticated && <>
                        <NavLink
                            exact
                            activeClassName="active"
                            to="/settings"
                            isActive={(match, location) => {
                                return location.pathname.includes(`/settings`)
                            }}
                            data-testid="settingslink"
                        >
                            <img src={SettingsIcon} alt="settings icon" width="20px" height="20px" />
                            <HighlightedText size="20px" textAlign="center" color="#fff" margin="1em">
                                Account Settings
                            </HighlightedText>

                        </NavLink>

                    </>}
                    <hr className="resp__bo"/>

                    {
                        this.state.authenticated ?
                            <NavLink exact activeClassName="active" to={{ pathname: "/feedback" }} data-testid="feedback">
                                <img src={FeedbackIcon} alt="feedback icon" width="20px" height="20px" />
                                <HighlightedText size="20px" textAlign="center" color="#fff" margin="1em">
                                    Feedback
                                </HighlightedText>

                            </NavLink>
                            :
                            <NavLink exact activeClassName="active" to={{ pathname: "/auth/register" }} data-testid="accountlink">
                                <img src={UnAuthorizedUserIcon} alt="unauthorized user icon" width="20px" height="20px" />
                                <HighlightedText size="20px" textAlign="center" color="#fff" margin="1em">
                                    Account
                                </HighlightedText>

                            </NavLink>
                    }
                </div>

                <div className="bottomDiv resp__bo">
                    <HighlightedText size="20px" textAlign="center" color="#fff" margin="1em 0" data-testid="quote">
                        Learn with confidence!
                    </HighlightedText>
                    {/* <Button background="#fff" color="#923d41" radius="7px" padding="10px 55px">
                        <b>Explore </b><img src={ArrowRed} alt="link" />
                    </Button> */}
                </div>


            </SideBar>
        )
    }
}

export default GeneralSideBar





const SideBar = styled.div`
    min-width: 15%;
    height: 100vh;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #923D41;

    hr{
        margin: 1em;
    }
    .middleDiv{
        position: absolute;
        top: 20%;
        text-align-last: center;
        padding: 0.5em;
        margin: 0.5em;

    }

    .feedbackDiv{
        position: absolute;
        top: 30%;
        text-align-last: center;
        padding: 0.5em;
        margin: 0.5em;

    }

    .bottomDiv{
        /* position: absolute;
        bottom: 20%; */
        text-align-last: center;
        padding: 0.5em;
        margin: 0.5em;
    }
    

    a{
        align-items: center;
        text-decoration: none;
        display: flex;
        padding: 0em  0 0 1em;

        &.active { // & - making a class name inside the tag itself
            background-color: rgba(128,128,128, 0.5);
            border-radius: 0.3em;
            margin: 0em 0.5em;
                
            .img {
                    filter: drop-shadow(0px 0px 13px #fff) brightness(1.2); 
            }
            h1 {
                font-weight: bold;
                }
        }

    }

   

    @media only screen and (max-width: 1080px){
        /* responsiveness code has to be at the bottom so it overrides any conflicting above css  */

        display: flex;
        width: 100%;
        height: 7vh;
        position:fixed;
        bottom:0;

        .bottomDiv{
            display: none;
        }

        .resp__bo{ // a jargon i created: explained in README.md more would follow
            display: none;
        }
        .topDiv{
            display: flex;
            text-align: center;
            width: 100%;
            justify-content: space-between;
            padding: 0 5px;

            // to make the code for responsiveness modular: 
            // ie appear similar to html above, 
            // you could embedd the tag just as they appear
            a{
                align-items: center;
                align-self: center;
                text-decoration: none;
                display: block;
                padding: 2% 2%;
                width: 25%;
                /* width: 20%; */

                h1{ // HighlightedText responsiveness
                    /* font-size: 10px; */
                    font-size: 50%;
                    margin: 0;
                }
                &.active {
                    margin: 0;
                }

            }
        }

    }

    

`

