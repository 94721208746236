
import React, { Component } from 'react';
import { Card } from '../../components/uiComponents/card'
import { Button } from '../../components/uiComponents/button'
import styled from 'styled-components';
import { HighlightedText } from '../../components/uiComponents/highlightedText';
import { Input } from '../../components/uiComponents/input';
import { forgottenPassword } from '../../utils/api/accountApi';
import { readableServerError } from '../../utils/utilFnx';
import ClipLoader from "react-spinners/ClipLoader";
import EmailIcon from "../../assets/icons/email.svg";


class ForgotPassword extends Component {


    constructor(props) {
        super();

        this.state = {
            email: null,
            loading: false,
            submitted: false,
            errorMessage: null
        }
    }


    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        // console.log(this.state)
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        forgottenPassword({ email: this.state.email }).then(data => {
            this.setState({ loading: false, submitted: true })

        }).catch(err => {
            this.setState({
                loading: false,
                errorMessage: readableServerError(err),
            })
        })
    }


    render() {

        return (
            <ForgotPasswordEmailFormContainer>
                {this.state.submitted ?
                    <><HighlightedText textAlign="center" color="#923D41">
                        Check your email
                    </HighlightedText>
                        <Card textAlign="center">
                            <img src={EmailIcon} width="100px"alt="email" />
                            <a href="/auth/login">Back to Login</a>

                        </Card>
                    </>
                    :
                    <>   <HighlightedText textAlign="center" color="#923D41">
                        Provide your e-mail
                    </HighlightedText>
                        <Card shadow="0px 12px 30px #923D411A" padding="2em 1em 1em">

                            <form onSubmit={this.handleSubmit}>
                                <Input placeholder="Email" type="email" name="email" onChange={this.handleChange} required />
                                {this.state.errorMessage && <p>{this.state.errorMessage}</p>}
                                <Button background="#923D41" radius="30px" margin="5em auto" width="90%">
                                    <ClipLoader color="#fff" loading={this.state.loading} size={10} />
                                    {!this.state.loading && <span>SUBMIT</span>}
                                </Button>
                            </form>
                        </Card>
                    </>
                }
            </ForgotPasswordEmailFormContainer>

        );
    }
}
export default ForgotPassword;




const ForgotPasswordEmailFormContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:25%;

    input{
        border: 1px solid black;
        border-radius: 3px;
        display: block;
        padding: 10px 20px;
        margin: 25px auto;
        width: 80%;
        height: 1.5em;

        text-align: left;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        color: #923D41;
    }


    input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #923D41;
        font-weight: normal;
        opacity: 1; /* Firefox */
      }


    button{
        display: block;
        height: 40px;
    }

    a{
        text-decoration: none;
        color: #923D41;
        font-size: 12px;
        text-align: center;
        display: block;
        margin-top: 2em;
    }

    p{
        color: #923D41;
        text-align: center;
        margin: auto;
        background: rgba(146, 61, 65, 0.1);
        width: fit-content;
        padding: 0.5em;
        border-radius: 0.5em
    }

    @media only screen and (max-width: 768px){
        display: block;
        margin: 20px auto;
        width:90%;
    }


`

