import React from 'react';
import styled from 'styled-components';
import { Stream } from "@cloudflare/stream-react";
import PulseLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/react";
import SnackBar from 'my-react-snackbar';
import { readableServerError, timeSince } from '../../../utils/utilFnx';
import { createVideoLog, createVideoLogInterraction } from '../../../utils/api/videoApi';


class VideoPlayer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            bufferingVideo: true,
            videoLog: {},
            videoLogInterraction: {},
            snackMessage: { type: null, message: null },
            baseTimeStamp: 0, // currently the video play duration cannot be botten, this get it right fron the beginning
            playTimeBaseStamp: 0, // this gets the value for play timestamp
            deltaMainTimeStamp: 0,
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false
    }

    componentDidMount = () => {
        // take care of leakages. https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
        this._isMounted = true
        this.onCreateOrGetVideoLog()
    }

    onCreateOrGetVideoLog = () => {
        createVideoLog(this.props.videoID).then((data) => {
            this._isMounted && this.setState({
                videoLog: data
            })
        })
    }



    analyticsMaker = (action, timeStamp, command) => {
        console.log(this.state.videoLog);
        if (this.state.videoLogInterraction.action && !this.state.videoLogInterraction.end_time && this.state.videoLog.id) {
            // NOTE: it doesnt send the incoming action but saves the previous one stored in state
            // hence, onpause, it send out long it played for
            let videoLogInterractionData = this.state.videoLogInterraction
            videoLogInterractionData.end_time = new Date()
            videoLogInterractionData.action = action === "ended" ? action : this.state.videoLogInterraction.action
            // NOTE: || 0 is to cater for times where timeStamp `undefined`
            videoLogInterractionData.current_timestamp = ((timeStamp / 1000) || 0).toFixed(8) || 0
            videoLogInterractionData.video_log = this.state.videoLog.id

            createVideoLogInterraction(this.state.videoLog.id, videoLogInterractionData).then(data => {

                if (command === "non-lethal") {
                    this.setState({
                        videoLogInterraction: videoLogInterractionData
                    })
                }
                else {
                    this.setState({
                        videoLogInterraction: {
                            start_time: new Date(),
                            action: action,
                            current_timestamp: timeStamp,
                            end_time: null
                        },
                        deltaMainTimeStamp: command === "delta" ? timeSince : 0
                    })
                }

            }).catch((err) => {
                console.log(err.response);
            })
        } else {
            this.setState({
                videoLogInterraction: {
                    start_time: new Date(),
                    action: action,
                    current_timestamp: timeStamp,
                    end_time: null
                }
            })
        }
    }

    onLoadedData = (e) => {
        // TODO: check which of the 2 is called when video is ready
        console.log("video has loaded, onLoadedData");
    }

    onLoadedMetaData = (e) => {
        this.setState({
            bufferingVideo: false
        })
        console.log("video has loaded, onLoadedMetaData");
    }
    onError = (e) => {
        /**
         Sent when an error occurs. 
         (e.g. the video has not finished encoding yet, 
            or the video fails to load due to an incorrect signed URL)
        */
        this.setState({
            snackMessage: { type: "error", message: readableServerError(e) }
        })


    }

    onProgress = (e) => {

        /**
         * Sent periodically to inform interested parties of progress 
         * downloading the media. Information about the current
         *  amount of the media that has been downloaded 
         * is available in the media element’s buffered attribute.
         */

        // console.log("video has loaded, onProgress");

    }

    onEnded = (e) => {

        /**
         * Sent when playback completes.
         */
        this.analyticsMaker(e.type, e.timeStamp - this.state.baseTimeStamp)
        // console.log("video has loaded, onEnded", e);

    }


    onLoadStart = (e) => {

        /**
         * Sent when loading of the media begins.
         */
        this.setState({
            baseTimeStamp: e.timeStamp,
            playTimeBaseStamp: e.timeStamp
        })
        // console.log("video has loaded, onLoadStart", e);

    }

    onPlay = (e) => {

        /**
         * Sent when the playback state is no longer paused,
         * as a result of the play method, or the autoplay attribute.
         */

        // get between pause and play
        // var vid = document.getElementsByTagName("myVideo");
        this.analyticsMaker(e.type)
        // console.log("video has loaded, onPlay", e);

    }


    onPause = (e) => {

        /**
         * Sent when the playback state is changed to paused (paused property is true).
         */
        this.analyticsMaker(e.type, this.state.playTimeBaseStamp - e.timeStamp)
        // console.log("video has loaded, onPause", e);

    }

    onPlaying = (e) => {

        /**
         * Sent when the media has enough data to start playing,
         *  after the play event, but also when recovering from 
         * being stalled, when looping media restarts, and
         *  after seeked, if it was playing before seeking.
         */
        if (this.state.deltaMainTimeStamp) {
            this.setState({
                playTimeBaseStamp: this.state.playTimeBaseStamp + (e.timeStamp - this.state.deltaMainTimeStamp),
                deltaMainTimeStamp: 0,
            })
        }
        this.analyticsMaker("analytics-loading-internet-speed", e.timeStamp - this.state.playTimeBaseStamp, "non-lethal")
        // console.log("video has loaded, onPlaying", e);
    }
    onSeeked = (e) => {

        /**
         * Sent when a seek operation completes.
         */
        this.analyticsMaker(e.type, e.timeStamp - this.state.playTimeBaseStamp, "delta")
        // console.log("video has loaded, onSeeked", e);

    }
    onSeeking = (e) => {

        /**
         * Sent when a seek operation begins.
         */
        // do nothing
        // console.log("video has loaded, onSeeking");

    }
    onSuspend = (e) => {

        /**
         * Sent when loading of the media is suspended; 
         * this may happen either because the download 
         * has completed or because it has been paused
         *  for any other reason.
         */
        // do nothing
        // console.log("video has loaded, onSuspend");

    }

    render = () => {
        return (

            <VideoPlayerContainer>
                <SnackBar
                    open={this.state.snackMessage.message}
                    message={this.state.snackMessage.message}
                    type={this.state.snackMessage.type}
                    position="bottom-right"
                    timeout={5000}
                />

                {this.state.bufferingVideo && <PulseLoader
                    color="#fff"
                    loading={this.state.bufferingVideo}
                    size={20}
                    css={LoadingAnimationStyle}
                />}

                {
                    // DEBUG: when ever a component expecially an external component
                    //  doesnt refresh on state change, use the && or the ? : operators
                    //  to make it refresh. Else it picks just the initial state
                    //  and doesnt respond to future props change
                    this.props.video && <Stream
                        controls
                        // DEBUG: `replaceAll` doesnt work for old broswers
                        src={this.props.video?.replace(/-/g, "")}
                        onLoadedData={this.onLoadedData}
                        onLoadedMetaData={this.onLoadedMetaData}
                        onError={this.onError}
                        onProgress={this.onProgress}
                        onEnded={this.onEnded}
                        onLoadStart={this.onLoadStart}
                        onSuspend={this.onSuspend}
                        onSeeking={this.onSeeking}
                        onSeeked={this.onSeeked}
                        onPlaying={this.onPlaying}
                        onPause={this.onPause}
                        onPlay={this.onPlay}
                    />
                }

            </VideoPlayerContainer>

        )
    }
}
export default VideoPlayer

const VideoPlayerContainer = styled.div`
    height: 60vh;
    width: 100%;
    background-color: black;
    border-radius: 5px;
    display: block;
    text-align: center;

    position: relative;

    iframe{
        height: 60vh !important;
    }

    div{
        /* to remove padding on video that makes other page content unclickable */
        padding-top: 0% !important;
    }

`



const LoadingAnimationStyle = css`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

`;
