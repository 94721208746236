import APIClient from './httpClient';



export const subscribeToAUser = async (subscribingToID) => {
    const api = new APIClient();
    const endpoint = `/`;

    const { data } = await api.SubscriberApi.post(endpoint, { subscribing_to: subscribingToID });
    return data;
}

export const getSubscribings = async () => {
    // get the users that the requester is subscribed to.
    const api = new APIClient();
    const endpoint = `/`;

    const { data } = await api.SubscriberApi.get(endpoint);
    return data;
}