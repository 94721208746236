import React from 'react';
import { HighlightedText } from "./highlightedText";
import styled from 'styled-components';


export function AshMidTitle(props) {

return <HighlightedText margin="0 1em 1em;" color="#535353" size="22.5px" weight="600">{props.title}</HighlightedText>

}

export const SuperScript = styled.sup`
    font-size: ${({ size }) => size};
    font-weight: ${({ weight }) => weight || "bold"};
    font-style: ${({ fontStyle }) => fontStyle || "normal"};
    font-stretch: normal;
    line-height: ${({ lineHeight }) => lineHeight || "normal"};
    letter-spacing: normal;
    color: ${({ color }) => color || "#000000"};
    margin: ${({ margin }) => margin || "0px"};
`
