

import React from 'react';
import styled from 'styled-components';

import { HighlightedText } from '../../../components/uiComponents/highlightedText';
import { getAuthToken } from '../../../utils/storage/auth';
import PromptToAuthenticate from '../../../components/uiComponents/promptToAuthenticate';
import { retrieveNote } from '../../../utils/api/noteApi';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from 'react-draft-wysiwyg';
import SnackBar from 'my-react-snackbar';
import { readableServerError } from '../../../utils/utilFnx';
import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/react";


class Note extends React.Component {

    constructor(props) {
        super();
        this.state = {
            authenticated: getAuthToken(),
            note: null,
            loading: false,
            snackMessage: { type: null, message: null },
        }
    }
    componentDidMount = () => {
        this.getNoteData()
    }

    componentDidUpdate(prevProps, prevState) {
        console.log(this.props.match.params.noteID);
        if (this.props.match.params.noteID !== prevProps.match.params.noteID) {
            this.getNoteData()

        }

    }

    getNoteData = () => {
        this.setState({
            loading: true
        })
        retrieveNote(this.props.match.params.noteID).then((data) => {
            this.setState({ ...data, loading: false })
        }).catch(err => {
            this.setState({
                loading: false,
                snackMessage: { "type": "error", "message": readableServerError(err) }
            })
        })
    }

    render() {
        console.log(this.state);
        if (this.state.authenticated) {

            return (
                <NoteContainer>
                    <SnackBar
                        open={this.state.snackMessage.message}
                        message={this.state.snackMessage.message}
                        type={this.state.snackMessage.type}
                        position="bottom-right"
                        timeout={5000}
                    />
    
                    <HighlightedText color="#923D41" margin="1em">{this.state.title || "Note"}</HighlightedText>
    
    
                    {this.state.loading ?
                        <SyncLoader color="#923D41" css={LoadingAnimationStyle}/>
                        :
                        <>
                            <Editor
                                toolbarHidden
                                readOnly
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                defaultContentState={this.state.note}
                            />
                            <HighlightedText color="#8b949e" size="15px" margin="1em">
                                By {this.state.user?.first_name} {this.state.user?.last_name}
                            </HighlightedText>
                        </>
                    }
    
                </NoteContainer>
            )
        }
        else{

            return (
                <PromptToAuthenticate message="The information here is copyrighted. Login to continue"/>
            )

        }
    }

}
export default Note;

const NoteContainer = styled.div`
    margin: 1em 2em;

    .wrapperClassName{
        color: #030508;
        background-color: #d8f3dc !important;
        border: #143601 1px solid;
        border-radius: 3px;
        padding: 5px;
        min-width: 100%;
    }
    
    .editorClassName{
                
        background-color: #d8f3dc !important;
        padding: 5px 15px;
    }


`


const LoadingAnimationStyle = css`
    display: block;
    margin: 10vh 10px;
    text-align: center;
`;