
export const TOPIC_SIDEBAR_REGEX = /\/topic\/\d+\/main\/\w+/;

export const HOMEPAGE_FILLER_CARDS_WITHOUT_GRADIENT = ["#023e8a", "#40916c", "#9d4edd", "#b07d62", "#723c70", "#212f45"]

export const HOMEPAGE_FILLER_CARDS_WITH_GRADIENT_AND_FALLBACK = [
    ["#799f0c", "to right, #ffe000, #799f0c"],
    ["#314755", "to right, #26a0da, #314755"], 
    ["#2b5876", "to right, #4e4376, #2b5876"],
    ["#e65c00", "to right, #F9D423, #e65c00"],
    ["#cc2b5e", "to right, #753a88, #cc2b5e"],
    ["#00467F", "to right, #A5CC82, #00467F"],
]




