import React, { Component } from 'react';
import styled from 'styled-components';
import UserIcon from '../../assets/icons/profile-user.svg'
// import SearchIcon from '../assets/icons/search_white.svg'
import { Button } from '../uiComponents/button'
import { userLogout } from '../../utils/api/accountApi'
import { deleteAuthToken, getAuthToken, getFirstName, getLastName } from '../../utils/storage/auth'
// import { Input } from './input';
import { LogoImg } from '../uiComponents/uiElements';
import MenuIcon from "../../assets/icons/menu1.svg";
import ProgressIcon from "../../assets/icons/graphic-progression_green.svg";
// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import TopicSideBar from "../sideBar/topicSideBar";
import { TOPIC_SIDEBAR_REGEX } from '../../utils/constants';
import { getItemFromLocal } from '../../utils/utilFnx';
import { Link } from 'react-router-dom'
import { Route } from 'react-router-dom';

class TopBar extends Component {

    constructor(props) {
        super(props)

        this.state = {
            logout_option: false,
            isOpen: false,
        }
    }


    handleClick = (e) => {

        this.setState(prevState => ({
            logout_option: !prevState.logout_option
        }));
    }

    handleLogout = (e) => {

        userLogout().then(data => {
            deleteAuthToken();
            localStorage.clear()
            window.location.reload();
        }).catch((err) => {
            deleteAuthToken();
            this.props.history.push("/home");
        })
    }

    render() {
        // console.log(this.props.location.pathname);
        return (
            <TopBarContainer>

                {TOPIC_SIDEBAR_REGEX.test(this.props.location.pathname) && <div className="resp__so">
                    <Drawer
                        open={this.state.isOpen}
                        onClose={() => this.setState({ isOpen: !this.state.isOpen })}
                        onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                        direction='left'>
                        <Route path={`${this.props.match.path}topic/:topicID/main/:contentType/:contentID`}
                            render={(props) =>
                                <TopicSideBar
                                    {...props}
                                    onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                />}
                        />
                        {/* <TopicSideBar {...this.props} onClick={() => this.setState({ isOpen: !this.state.isOpen })}/> */}
                    </Drawer>
                    <Button
                        background="#e8ecf1"
                        padding="5px 10px"
                        margin="5px"
                        onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                    >
                        {getAuthToken() && <img src={MenuIcon} alt="menu icon" width="20px" />}
                        <img src={ProgressIcon} alt="menu icon" width="20px" style={{ marginLeft: 10 }} />
                    </Button>
                </div>}
                {(!TOPIC_SIDEBAR_REGEX.test(this.props.location.pathname) || !this.state.isOpen)
                    && <Link
                        className="resp__so SKIDEO_HOME"
                        to="/home"
                    >
                        Skideo
                    </Link>}

                <form className="resp__bo">
                    {/* <Input type="text"
                        placeholder="Search"
                        margin="0"
                        padding="0.3em 1em"
                        size="15px"
                        width="40%"
                        radius="5px  0 0 5px"
                        weight="500"
                        border="1px solid grey"
                        color="#923D41"
                    />
                    <Button radius="0 5px 5px 0" height="34px" padding="0 20px">

                        <img src={SearchIcon} width="15px" alt="Search" />
                    </Button> */}
                </form>


                {getAuthToken() !== null ?
                    <div className="auth_container"
                        onClick={this.state.logout_option ? null : this.handleClick}
                        data-testid="profile-logout-button">
                        <LogoImg src={getItemFromLocal("profile_pic") || UserIcon} margin="auto 0.75em" width="30px" height="30px" alt="profile picture" radius="50%" />

                        {this.state.logout_option ?
                            <><Button onClick={this.handleClick} 
                                className="btn-group">Cancel</Button>
                                <Button onClick={this.handleLogout}
                                    className="logout"
                                > Log out</Button>
                            </>
                            : <span className="resp__bo">
                                {`${getFirstName()} ${getLastName()}`}
                            </span>}

                    </div> : <div className="unauth_container">
                        <a className="login" href="/auth/login" >
                            Login
                        </a>
                        <a className="signup" href="/auth/register">
                            Register
                        </a>
                    </div>}

            </TopBarContainer>
        )
    }
}

export default TopBar



const TopBarContainer = styled.div`
    width: 100%;
    height: 50px;
    box-shadow: 0px 1px 6px #00000029;
    justify-content: space-between;
    display: flex;

    .SKIDEO_HOME{
        font-size: 25px;
        color: #923D41;
        padding: 5px 7px;
        align-self: center;
        text-align: center;
        font-weight: bold;
        text-decoration: none;
    }

    .resp__so{
        align-self: center;
    }


    form{
        align-items: center;
        display: flex;
        margin-left: 2em;
        width: 50%;

        input{
            &:hover{
                border :1px solid rgba(120,120,120, 0.5);
                box-shadow: inset 0 0 0 2px rgba(120,120,120, 0.5);
            }

            &:focus {
                outline: none !important;
                box-shadow: inset 0 0 0 2px rgba(146, 61, 65, 0.5);
                border: 1px solid #923D41;
            }
            
        }

    }

    .auth_container{
        display: flex;
        margin: 10px 2em;
        cursor:pointer;


        span{
            align-self: center;
        }

    }

    .unauth_container{
        margin: 0 1em;
        align-self: center;
        display: flex;

        .login{
            text-decoration: none;
            padding: 7px 15px;
            color: rgba(31, 58, 147, 1);
            background: rgba(137, 196, 244, 0.7);
            margin: 0 1em;
            display: block;
            border-radius: 1em;
        }
        .signup{
            text-decoration: none;

            padding: 7px 15px;
            color: rgba(30, 130, 76, 1);
            background: rgba(0, 230, 64, 0.3);
            /* margin-top: 19em; */
            display: block;
            border-radius: 1em;
        }
    }

    .btn-group{           
            padding: 0px 40px;
            margin: 0px 10px;
        }

    .logout{
            background:#ffffff;
            color: red;
            border: 2px solid green;
            padding: 0px 36px;
            margin: 0px 10px;
        }


    @media only screen and (max-width: 768px){
        /* responsiveness code has to be at the bottom so it overrides any conflicting above css  */

        width: 100%;
        height: 45px;
        box-shadow: 0px 3px 6px #00000029;
      
        .auth_container{
            
            display: flex;
            margin: 5px 0em;
            cursor:pointer;


            span{
                align-self: center;
            }
    
        }
    
        input{
            padding: 1em 1em;
            height: -webkit-fill-available;
            border: none;
            font-size: 12px;
            width: 80%;
    
        }
    
        form{
            display: none;
            margin-left: 1em;
            width: 50%;
            float: left;
    
        }

          
    }
`


