
import React from 'react';
import styled from 'styled-components';
import { HighlightedText } from '../../../components/uiComponents/highlightedText';
import { enrollInQuiz, getSyllabusList, retrieveFullTopic } from "../../../utils/api/topicApi";
import { Link } from 'react-router-dom'
import { Card } from "../../../components/uiComponents/card";
import { AshMidTitle } from "../../../components/uiComponents/text";
import { Button } from '../../../components/uiComponents/button';
import { getAuthToken } from '../../../utils/storage/auth';
import {
    appendItemToLocalArray,
    durationReadableFromNumer,
    getItemFromLocal,
    readableServerError,
    setItemToLocal
} from '../../../utils/utilFnx';
import { getUserProfile } from '../../../utils/api/accountApi';
import SnackBar from 'my-react-snackbar';
import PrivateUser from "../../../assets/icons/block-user.svg";
import SyllabusContentTile from "../../../components/topic/syllabusSection";
import UserIcon from '../../../assets/icons/profile-user.svg'
import BarLoader from "react-spinners/BarLoader";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { getSubscribings, subscribeToAUser } from '../../../utils/api/subscriberApi';

class TopicOverview extends React.Component {

    constructor(props) {
        super();
        this.state = {
            authenticated: Boolean(getAuthToken()),
            topic: {},
            instructorProfile: {},
            topicSyllabus: [{ syllabus_contents: [] }],
            subscribes: [], // who the user has subscribed to
            loadingTopic: false,
            loadingSyllabus: false,
            loadingProfile: false,
            loadingFollowing: false,
            loadingEnrollment: false,
            snackMessage: { type: null, message: null },

        }
    }


    componentDidMount = () => {
        this._isMounted = true
        // check whether it is coming from state
        if (this.props.location.state != null) {
            this._isMounted && this.setState({
                topic: this.props.location.state,
            })
            this.getTopicSyllabusData()
            this.getUserProfileData(this.props.location.state.user.id)
        }
        else {
            // if is it not coming from state, fetch from db
            this.getTopicFullDetails()
        }

        const cachedSubscribes = getItemFromLocal(`subscribes-${new Date().toISOString().slice(0, 10)}`)
        if (!cachedSubscribes) {
            // we dont put subscribes this.state
            this.userFollowings()
        }


    }

    componentWillUnmount = () => {
        this._isMounted = false
    }


    getTopicFullDetails = () => {
        this._isMounted && this.setState({
            loadingTopic: true
        })
        retrieveFullTopic(this.props.match.params.topicID)
            .then(data => {
                // console.log(data)
                this._isMounted && this.setState({
                    topic: data,
                    topicSyllabus: data.syllabus_sections,
                    loadingTopic: false,
                })
                this.getUserProfileData(data.user.id)
            }).catch((err) => {
                this._isMounted && this.setState({
                    loadingTopic: false,
                    snackMessage: { type: "error", message: readableServerError(err) },
                })
            });
    }


    getTopicSyllabusData = () => {
        this._isMounted && this.setState({
            loadingSyllabus: true,
        })
        getSyllabusList(this.props.match.params.topicID)
            .then(data => {
                // console.log(data);
                this._isMounted && this.setState({
                    topicSyllabus: data,
                    loadingSyllabus: false,
                })
            }).catch((err) => {
                this._isMounted && this.setState({
                    loadingSyllabus: false,
                    snackMessage: { type: "error", message: readableServerError(err) },
                })
            });
    }



    getUserProfileData = (userId) => {
        this._isMounted && this.setState({
            loadingProfile: true
        })
        getUserProfile(userId).then((data) => {
            this._isMounted && this.setState({
                instructorProfile: data,
                loadingProfile: false,
            })
        }).catch((err) => {
            this._isMounted && this.setState({
                loadingProfile: false,
                snackMessage: err.response?.status === 404 ?
                    { type: "info", message: "Instructor's profile is private" }
                    : { type: "error", message: readableServerError(err) },
            })
        })
    }

    enrollLearner = async (e) => {
        this.setState({
            loadingEnrollment: true
        })

        if (this.state.authenticated) {
            await enrollInQuiz(this.state.topic.id).then(data => {
                this.setState({
                    loadingEnrollment: false,
                })
            }).catch((err) => {
                console.log("error");
                this.setState({
                    loadingEnrollment: false,
                    snackMessage: { type: "error", message: readableServerError(err) },
                })
            })

        }
    }

    userFollowings = () => {
        this.setState({
            loadingFollowing: true
        })
        if (getAuthToken()) {
            getSubscribings().then(data => {
                setItemToLocal(`subscribes-${new Date().toISOString().slice(0, 10)}`, data)
                this.setState({
                    loadingFollowing: false,
                })
            }).catch(err => {
                this.setState({
                    loadingFollowing: false,
                    snackMessage: { "type": "error", "message": readableServerError(err) }
                })
            })
        }
    }

    onFollow = (e) => {
        if (getAuthToken()) {
            this.setState({
                loadingFollowing: true
            })
            subscribeToAUser(this.state.instructorProfile.user.id).then((data) => {
                appendItemToLocalArray(`subscribes-${new Date().toISOString().slice(0, 10)}`, data)
                this.setState({
                    loadingFollowing: false,
                    snackMessage: { "type": "success", "message": `You are following ${this.state.instructorProfile.user.first_name}` }
                })

            }).catch((err) => {
                this.setState({
                    loadingFollowing: false,
                    snackMessage: { "type": "error", "message": readableServerError(err) }
                })


            })

        } else {
            this.props.history.push("/auth/login");
        }

    }



    render = () => {
        // console.log(this.state.topicSyllabus[0]?.syllabus_contents[0]);
        // console.log(this.state.topicSyllabus);

        /* what the code above here is doing is that it check first 
        if there is a current_state variable in the `user_enrollment` object
        if present, it send the syllabus content associated with it since
         it doesnt have to `content` data by itself
         hence, it check in it fron `this.state.topicSyllabus[0]?.syllabus_contents`
         else, it gets the first one.
          */

        let firstContent = {}

        if (this.state.topic.user_enrollment?.current_stage) {
            this.state.topicSyllabus.forEach((syl_sec) => {
                firstContent = syl_sec.syllabus_contents.find(
                    ({ id }) =>
                        id === this.state.topic.user_enrollment?.current_stage
                )
                if (Boolean(firstContent)) {
                    return
                }

            })
            if (!Boolean(firstContent)) {
                firstContent = this.state.topicSyllabus[0]?.syllabus_contents[0]
            }
        } else {
            firstContent = this.state.topicSyllabus[0]?.syllabus_contents[0]
        }

        return (
            <TopicOverviewContainer>
                <SnackBar
                    open={this.state.snackMessage.message}
                    message={this.state.snackMessage.message}
                    type={this.state.snackMessage.type}
                    position="bottom-right"
                    timeout={5000}
                />
                {this.state.loadingTopic ?
                    <center>
                        <ClipLoader color="#923D41" />
                    </center>
                    :
                    <>
                        <div className="introHeader">
                            <HighlightedText margin="1em" color="#923D41">{this.state.topic.title}
                            </HighlightedText>
                            <Card background="transparent">

                                {firstContent ?
                                    <>
                                        {!this.state.authenticated &&
                                            <Link className="viewLink" height="3em" margin="3em" to={{
                                                pathname: `/topic/${this.props.match.params.topicID}/main/${firstContent.content_type}/${firstContent.content?.id}`,
                                                state: this.state.topicSyllabus
                                            }}>
                                                View
                                            </Link>
                                        }
                                        {this.state.topic.user_enrollment ?
                                            <Link
                                                className="continueLink"
                                                height="3em"
                                                to={{
                                                    pathname: `/topic/${this.props.match.params.topicID}/main/${firstContent.content_type}/${firstContent.content?.id}`,
                                                    state: this.state.topicSyllabus
                                                }}>
                                                Continue
                                            </Link>
                                            : <Link
                                                className="joinLink"
                                                height="3em"
                                                onClick={this.enrollLearner}
                                                to={this.state.authenticated ? {
                                                    pathname: `/topic/${this.props.match.params.topicID}/main/${firstContent.content_type}/${firstContent.content?.id}`,
                                                    state: this.state.topicSyllabus
                                                } : {
                                                    pathname: '/auth/login'

                                                }}
                                                disabled={this.state.loadingTopic}
                                            >
                                                {this.state.loadingEnrollment ? "Loading..." : "Join"}
                                            </Link>}
                                    </>
                                    :
                                    <p className="notReady">
                                        Not ready
                                    </p>
                                }
                            </Card>

                        </div>

                        <Card display="flex" margin="1em" flexFlow="wrap">
                            <Card display="flex" margin="1em">
                                <HighlightedText size="1em">
                                    Instructor:
                                </HighlightedText>
                                <HighlightedText margin="0 0.5em" size="1em" weight="normal">
                                    {this.state.topic.user?.first_name} {this.state.topic.user?.last_name}
                                </HighlightedText>
                            </Card>

                            <Card>
                                <Card display="flex" margin="1em">
                                    <HighlightedText size="1em">
                                        Duration:
                                    </HighlightedText>
                                    <HighlightedText margin="0 0.5em" size="1em" weight="normal">
                                        {durationReadableFromNumer(this.state.topic.duration) || "Indeterminate"}
                                    </HighlightedText>
                                </Card>
                            </Card>

                            <Card display="flex" margin="1em">
                                <HighlightedText size="1em">
                                    Level:
                                </HighlightedText>
                                <HighlightedText margin="0 0.5em" size="1em" weight="normal" textTransform="capitalize">
                                    {this.state.topic.level ?? "None"}
                                </HighlightedText>
                            </Card>

                            {/* <Card>
                        <HighlightedText margin="1em 1em" size="1em">
                            Rating: 4.5
                            </HighlightedText>
                    </Card> */}
                        </Card>

                        <Card display="flex" margin="1em" flexFlow="wrap">
                            <Card margin="1em 0em" size="1em" width="60%">
                                <HighlightedText
                                    margin="0 1em 1em;"
                                    color="#535353"
                                    size="22.5px"
                                    weight="600"
                                    width="100%"
                                >
                                    Description
                                </HighlightedText>

                                <HighlightedText margin="2em 1.5em" size="16px" weight="normal" lineHeight="1.5">
                                    {this.state.topic.description}

                                </HighlightedText>
                            </Card>
                            {this.state.topic.learning_goals?.length > 0 &&
                                <Card margin="1em 1em" size="1em">
                                    <AshMidTitle title="What you'll learn" />
                                    <ul>
                                        {
                                            this.state.topic.learning_goals?.map((goal, i) => <li key={i}>
                                                {goal}
                                            </li>)
                                        }

                                    </ul>
                                </Card>
                            }
                        </Card>

                        <Card padding="2em 0" margin="1em">
                            <AshMidTitle title="Course Syllabus" />
                            {this.state.loadingSyllabus ?
                                <center>
                                    <ClipLoader color="#923D41" />
                                </center>
                                :
                                this.state.topicSyllabus.map((syllabus, i) => {

                                    return <SyllabusContentTile
                                        key={i}
                                        data={syllabus}
                                        topicID={this.props.match.params.topicID}
                                    />

                                })
                            }


                        </Card>
                    </>
                }
                {this.state.loadingProfile ?
                    <center>
                        <ClipLoader color="#923D41" />
                    </center>
                    : this.state.instructorProfile.public_profile ?
                        <Card display="flex" margin="1em" space="space-between" flexFlow="wrap">
                            <Card margin="1em 1em" size="1em" width="60%">
                                <AshMidTitle title="About the instructor" />

                                <HighlightedText margin="2em" size="16px" weight="normal" lineHeight="1.5">
                                    {this.state.instructorProfile.biography}
                                </HighlightedText>
                            </Card>

                            <Card
                                textAlign="center"
                                margin="1em 1em"
                                size="1em"
                                shadow="0px 12px 30px #923D411A"
                                radius="1em"
                                width="20%"
                                padding="1em"
                            >

                                <img src={this.state.instructorProfile.profile_pic ?? UserIcon} alt="instructor profile" />
                                <HighlightedText margin="1em" size="20px" weight="600" lineHeight="1.5">
                                    {this.state.instructorProfile.user?.first_name} {this.state.instructorProfile.user?.last_name}
                                </HighlightedText>
                                <HighlightedText margin="0em" size="16px" weight="normal" lineHeight="1.5">
                                    {this.state.instructorProfile.subjects_of_interest?.join(", ")}
                                </HighlightedText>
                                {
                                    getAuthToken() ? getItemFromLocal(`subscribes-${new Date().toISOString().slice(0, 10)}`, [])
                                        ?.some(({ subscribing_to }) => {
                                            if (typeof (subscribing_to) === "number") {
                                                return subscribing_to === this.state.instructorProfile.user?.id
                                            } else {
                                                return subscribing_to.id === this.state.instructorProfile.user?.id
                                            }
                                        }
                                        ) ?
                                        <HighlightedText
                                            color="rgba(31, 58, 147, 1)"
                                            background="rgba(137, 196, 244, 0.7)"
                                            weight="normal"
                                            padding="5px 15px"
                                            width="min-content"
                                            margin="0 auto"
                                            size="15px"
                                            radius="15px"
                                            textAlign="center"
                                        >
                                            Following
                                        </HighlightedText>
                                        :
                                        < Button
                                            radius="1em"
                                            margin="0.5em"
                                            onClick={this.onFollow}
                                            data-testid="followbutton"
                                            disabled={this.state.loadingFollowing}
                                        >
                                            {this.state.loadingFollowing ?
                                                <BarLoader color="#fff" css={LoadingAnimationStyle} />
                                                :
                                                "Follow"
                                            }
                                        </Button>
                                        : null
                                }
                            </Card>

                        </Card>
                        :
                        <Card
                            margin="1em"
                            background="#f1f8ff"
                            border="1px solid #c8e1ff"
                            padding="1.5em"
                            color="black"
                            display="flex"
                            alignItems="center"
                            space="center"
                            hoverBorderColor="#c8e1ff"
                        >
                            <img src={PrivateUser} width="50px" alt="private user" />
                            This account's profile is private.
                        </Card>
                }

                {
                    // TODO: course feedback 
                }

            </TopicOverviewContainer >

        )
    }

}

export default TopicOverview





const TopicOverviewContainer = styled.div`

    margin: 1em 1em 5em 1em;

    ul {
        list-style-type: square;
        color: black;

        li{
            padding: 0 0 0.5em;
        }
    }
    
    .introHeader{
        display: flex;
        justify-content: space-between;
        align-items: center;

        div{
            .viewLink{
                background-color: rgba(0, 177, 106, 1);
            }

            .joinLink{
                margin: 0 0 0 20px;
            }

            .continueLink{
                color: rgba(142, 68, 173, 1);
                background-color: rgba(213, 184, 255, 0.3);
                padding: 7px 30px;
                border-radius: 15px;
                font-weight: 600;
            }

            .notReady{
                padding: 7px 15px;
                color: rgba(207, 0, 15, 1);
                background: rgba(231, 76, 60, 0.3);
                /* margin-top: 19em; */
                display: block;
                border-radius: 1em;
            }
        }

    }
    
    div{
        /* can be taken off later */
        max-width: 90%;
    }

    a{
        text-decoration: none;
        padding: 7px 53px;
        background-color: #923d41;
        color: #fff;
        border-radius: 2px;
    }

    @media only screen and (max-width: 768px){
        margin: 0 0 5em;

        div{
            width: 100%;
        }

        .introHeader{
            div{
                flex-flow: wrap;
                display: flex;
                a{
                    margin: 8px !important;
                    padding: 10px;
                }
            }
            
            h1{
                margin:0.5em;
            }

        }
    }
`


const LoadingAnimationStyle = css`
    display: block;
    margin: 10px;
`;