
import React from 'react';
import { Card } from '../uiComponents/card'
import styled from 'styled-components';
import Course from '../../assets/images/course.svg'
import CourseImg from '../../assets/images/courseImage.jpg'
import { HighlightedText } from '../uiComponents/highlightedText';
import { SuperScript } from '../uiComponents/text';
import { enrollment, timeSince } from '../../utils/utilFnx';
import { Link } from 'react-router-dom'
import { LogoImg } from "../uiComponents/uiElements";
import BackgroundFiller from './backgroundFiller';

class GenericTopicCard extends React.Component {

    render() {
        return (
            < GenericTopicCardContainer to={{
                pathname: `/topic/${this.props.data.id}/overview`,
                state: this.props.data
            }} data-testid="genericcard">

                {/* Bigger card */}
                {/* Picture card at the top */}
                {/* Author picuture or logo card */}
                {/* Name of the course */}
                {/* Number of student who have taken  */}
                {/* shen the course was posten */}

                {/* NOTE: image dimensions are set in the `topicImage` css class  */}

                {
                    this.props.data.thumbnail ?
                        <img className="topicImage" src={this.props.data.thumbnail ?? CourseImg} alt="topic" />
                        :

                        <BackgroundFiller data={this.props.data} />
                }

                <Card display="flex" margin="0.5em 0.5em 0">
                    <Card>
                        <LogoImg
                            src={this.props.data.user.profile_pic ?? Course}
                            alt="teacher image"
                            width="40px"
                            height="40px"
                            radius="50%"
                            background="#00b16a50"
                        />
                    </Card>
                    <Card margin="0 0.5em" width="100%">
                        <Card display="flex" space="space-between">

                            <HighlightedText className="title" color="#923D41" size="16px" weight="600" margin="0em 0em" width="100%">
                                {this.props.data.title}
                            </HighlightedText>
                            {this.props.data.user_enrollment && <HighlightedText
                                color="rgba(142,68,173,1)"
                                background="rgba(213,184,255,0.3)"
                                padding="3px 8px"
                                size="12px"
                                weight="600"
                                margin="0em 0em"
                                width="fit-content"
                                radius="10px"
                                height="min-content"
                            >
                                Enrolled
                            </HighlightedText>}
                        </Card>
                        <Card>

                            <HighlightedText color="grey" size="15px" weight="500" margin="0.2em 0.2em" width="100%" data-testid="name">
                                {this.props.data.user.first_name} {this.props.data.user.last_name}
                                <SuperScript color="#923D41" size="10px">T</SuperScript>

                            </HighlightedText>
                        </Card>
                        <Card display="flex">
                            <HighlightedText
                                color="grey"
                                size="13px"
                                weight="normal"
                                margin="0.2em 0.2em" data-testid="enrollment">{enrollment(this.props.data.enrollment)}</HighlightedText>
                            <HighlightedText
                                color="grey"
                                size="13px"
                                weight="normal"
                                margin="0.2em 0.2em 0" data-testid="time">| {timeSince(new Date(this.props.data.created_datetime))}</HighlightedText>
                        </Card>
                    </Card>
                </Card>


            </ GenericTopicCardContainer>
        )
    }

}

export default GenericTopicCard



const GenericTopicCardContainer = styled(Link)`
    box-shadow: 0px 12px 30px #923D411A;
    padding: 0 0em 0.5em;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    margin: 1em;
    border: 1px solid transparent;
    cursor: pointer;
    width: calc(25% - 2.25em);
    height: initial;

    .topicImage{
        width:100%;
        height: 175px;
    }

    .title{
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px;
        max-height: 40px;
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }

    @media only screen and (max-width: 1350px){
        width: calc(33% - 2.25em);

    }

    @media only screen and (max-width: 900px){
        width: calc(50% - 2.25em);

    }

    @media only screen and (max-width: 620px){

        width: 100%;
        height: min-content;
        margin: 1em 0;

        @media only screen and (min-width: 400px){
            .topicImage{
                width: 100%;
                height: 100%;
            }
            .coloredBackground{
                height: 200px;
            }
        }

    }

`