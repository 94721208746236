import React from 'react';
import { Card } from '../uiComponents/card';
import { HighlightedText } from '../uiComponents/highlightedText';
import InfoIcon from "../../assets/icons/info.svg";
import QuestionUnAnswered from '../../assets/icons/question.svg'
import QuestionAnswered from '../../assets/icons/question_fill.svg'
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import { retrieveQuizSummary } from '../../utils/api/quizApi';



class QuizExtraInfoCard extends React.Component {
    constructor(props) {
        super();
        this.state = {
            quizSummary: {},
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.quizSummary.id !== this.state.quizSummary.id && this.props.submittedQuiz) {
            // console.log("here");
            if (this.props.quizSummary.end_time) {
                this.setState({
                    quizSummary: this.props.quizSummary
                })
            } else {
                this.getQuizSummary()
            }

        }

    }
    getQuizSummary = () => {
        // console.log(this.state.quizSummary);
        retrieveQuizSummary(this.props.quizSummary.id).then((data) => {
            console.log(data);
            this.props.updateUserSummaries(data)
            this.setState({
                quizSummary: data
            })
        })
    }

    render = () => {
        // console.log(this.props.quizSummary, this.props.quizSummary.id);
        return <QuizExtraInfoCardContainer className="">
            {this.props.submittedQuiz ? <> <HighlightedText margin="1em">
                {this.state.quizSummary.total}
            </HighlightedText>
                <HighlightedText color="grey" size="0.75em" margin="0.2em">
                    <img className="deserve-little-margin" src={InfoIcon} alt="info" width="10px" />Pass mark: {this.props.quiz.pass_mark}
                </HighlightedText>
                <HighlightedText color={this.state.quizSummary.total >= this.props.quiz.pass_mark ? "#2ea44f" : "#cb2431"} size="0.75em" >
                    {this.state.quizSummary.total >= this.props.quiz.pass_mark
                        ? "You passed with gusto!"
                        : "You can do better, Try again."
                    }
                </HighlightedText>
                <HighlightedText color="grey" size="0.75em" weight="normal" margin="0.2em">
                    {`Highest: ${this.props.quiz.quiz_overall_statistics?.total__max} `}
                    |
                    {` Average: ${this.props.quiz.quiz_overall_statistics?.total__avg} `}
                    |
                    Lowest: {this.props.quiz.quiz_overall_statistics?.total__min}
                </HighlightedText>
                <Link className="closeQuiz" to={`/topic/${this.props.topicID}/main/quiz/${this.props.quizID}/summaries`}>
                    Close Quiz
                </Link>
            </> :
                [...Array(this.props.questionLength).keys()].map((questionNumberIndex, index) => {

                    return <a key={index} href={`#question-${index}`}>

                        <Card key={index} display="flex" color="#000" margin="0.5em 0" alignItems="center">
                            <img src={this.props.answers[index] ? QuestionAnswered : QuestionUnAnswered} alt="answered/unanswered" width="15px" height="15px" />
                            <HighlightedText color="grey" size="0.85em" weight="normal" margin="0.2em 0.5em">
                                Question {questionNumberIndex + 1}
                            </HighlightedText>
                        </Card>
                    </a>
                })
            }
        </QuizExtraInfoCardContainer>

    }

}

export default QuizExtraInfoCard;


const QuizExtraInfoCardContainer = styled.div`

    width: 15%;
    height: fit-content;
    margin: 2em 1em 1em;
    padding: 1em;
    background-color: white;
    text-align: center;
    position: sticky;
    top: 10%;


    .closeQuiz{
        text-decoration: none;
        background-color: #0366d64d;
        padding: 0.5em;
        margin: 1em 0.5em 0.5em;
        display: block;
        color: #053f84;
        border-radius: 10px;

    }

    a{
        text-decoration: none;
    }

    @media only screen and (max-width: 768px){
        width: 100%;
    }



`