

import React from 'react';
import styled from 'styled-components';
import { Card } from '../../../components/uiComponents/card';
import { HighlightedText, HightlightedSpan } from '../../../components/uiComponents/highlightedText';
import { Link } from 'react-router-dom';
import EmptyIcon from "../../../assets/icons/empty.svg";
import { convertTimeDelta } from '../../../utils/utilFnx';



class QuizSummary extends React.Component {


    render () {
        const summaries = this.props.quiz.user_summaries?.filter(summary => (summary.end_time !== null)) || []
        return (
            <QuizSummaryContainer>
                <Card display="flex" space="space-between" background="transparent" alignItems="center">
                    <HighlightedText size="25px">
                        Quiz summaries
                    </HighlightedText>


                    <Link to={{
                        pathname: `/topic/${this.props.match.params.topicID}/main/quiz/${this.props.match.params.quizID}/exam`
                    }}>
                        New quiz
                    </Link>


                </Card>
                <Card display="flex" background="transparent" margin="2em 0" flexFlow="wrap">
                    {summaries.length === 0 ?
                        <Card
                            display="block"
                            margin="2em auto"
                            padding="2em"
                            textAlign="center"
                            background="#f1f8ff"
                            border="1px solid #c8e1ff"
                            hoverBorderColor="#c8e1ff"

                        >
                            <img src={EmptyIcon} alt="empty" width="50px" />
                            <HighlightedText weight="normal" size="15px" margin="1em">
                                You have not completed any quiz yet.
                                Start a new quiz.
                            </HighlightedText>

                            <Link to={{
                                pathname: `/topic/${this.props.match.params.topicID}/main/quiz/${this.props.match.params.quizID}/exam`
                            }}>
                                Start a new quiz
                            </Link>
                        </Card>

                        :
                        summaries.map((summary, i) => {
                            return <this.summaryCard key={i} summary={summary} />
                        })
                    }

                </Card>


            </QuizSummaryContainer>
        )
    }

    summaryCard = (summaryProps) => {
        // console.log(summaryProps, summaryProps.summary.total ,this.props.quiz.pass_mark);
        const passed = summaryProps.summary.total >= this.props.quiz.pass_mark
        const timeSpent = convertTimeDelta(summaryProps.summary.end_time, summaryProps.summary.start_time)
        return (
            <Card
                padding="1em 2em"
                background={passed ? "#e5ffec" : "#ffeef0"}
                radius="5px"
                border={passed ? "1px solid #2ea44f" : "1px solid #cb2431"}
                hoverBorderColor="#ebedef"
                margin="1em">
                <HighlightedText color="#586069" size="30px" display="flex" alignItems="center">
                    {summaryProps.summary.total}
                    <HightlightedSpan weight="normal" size="18px" margin="0.5em 0 0.5em 1em">
                        points
                    </HightlightedSpan>
                </HighlightedText>
                <HighlightedText weight="500" size="22px" display="flex" alignItems="center">
                    {timeSpent[0]}
                    <HightlightedSpan weight="normal" size="18px" margin="0.5em 0 0.5em 1em">
                        {timeSpent[1]}
                    </HightlightedSpan>
                </HighlightedText>
                <HighlightedText weight="normal" size="18px" color={passed ? "#2ea44f" : "#cb2431"}>
                    {passed ? "passed!" : "Try again"}
                </HighlightedText>
            </Card>

        )
    }
}


export default QuizSummary;


const QuizSummaryContainer = styled.div`
    background-color: transparent;
    margin: 2em 2em 12vh;

    a{
        color: #ffffff;
        text-decoration: none;
        background-color: #923d41;
        border: 0;
        padding: 7px 53px;
        margin: 10px 0px;
        border-radius: 2px;
        width: auto;
        height: auto;
        text-align: center;
    }

    @media only screen and (max-width: 768px){
        a{
            padding: 5px 10px;
        }
    }
`