

import React from 'react';
import styled from 'styled-components';
import { Card } from '../../../components/uiComponents/card';
import { HighlightedText } from '../../../components/uiComponents/highlightedText';
import { Button } from '../../../components/uiComponents/button';
import { Collapse } from 'react-collapse';
import LikedIcon from '../../../assets/icons/liked.svg'
import UnLikedIcon from '../../../assets/icons/unliked.svg'
import LessonNotes from '../../../components/media/lessonNote';
import DiscussionBoard from '../../../components/media/discussionBoard';
import { retrieveVideo, updateVideoInterraction } from '../../../utils/api/videoApi';
import { getAuthToken } from '../../../utils/storage/auth';
import PromptToAuthenticate from '../../../components/uiComponents/promptToAuthenticate';
import VideoPlayer from './videoPlayer';


class MediaPage extends React.Component {

    constructor(props) {
        super();
        this.state = {
            video: {},
            collapseNotes: false,
        }
    }


    componentDidMount = () => {
        this._isMounted = true
        this.getVideoData()
    }
    componentWillUnmount = () => {
        this._isMounted = false
    }
    componentDidUpdate(prevProps, prevState) {
        // console.log(this.props.match.params.quizID, prevProps.match.params.quizID);

        if (this.props.match.params.videoID !== prevProps.match.params.videoID) {
            this.getVideoData()
        }
    }

    getVideoData = () => {
        retrieveVideo(this.props.match.params.videoID).then((data) => {
            // console.log(data);
            this._isMounted && this.setState({
                video: data,
            })
        })
    }

    videoLikeInterraction = (e) => {
        updateVideoInterraction(this.state.video.id, { like: !this.state.video.liked_by_user }).then((data) => {
            this.setState({
                video: { ...this.state.video, liked_by_user: data.like }
            })
        })
    }

    onCollapse = (e) => {
        this.setState({ collapseNotes: !this.state.collapseNotes })
    }

    render() {
        // console.log(this.state.video.video);
        return (

            <MediaPageContainer>


                {/* Video */}
                <section>
                    <VideoPlayer
                        video={this.state.video.video}
                        videoID={this.props.match.params.videoID}
                    />
                </section>
                <section>

                    <div className="introHeader">
                        <HighlightedText margin="1em" color="#923D41">{this.state.video.title}
                        </HighlightedText>
                        {getAuthToken() && <Button
                            height="3em"
                            padding="10px"
                            background="transparent"
                            onClick={this.videoLikeInterraction}
                            data-testid="likebutton"
                        >
                            <img src={this.state.video.liked_by_user ? LikedIcon : UnLikedIcon} width="20px" alt={this.state.video.liked_by_user ? "liked" : "like"} />
                        </Button>}

                    </div>

                    <Card className="lessonNotes" radius="0.5em" margin="1em" padding="1em">

                        <HighlightedText margin="1em" size="16px" weight="" lineHeight="1.5">
                            Notes
                        </HighlightedText>

                        {!this.state.collapseNotes && <LessonNotes data={this.state.video.description?.substr(0, 250)} />}

                        <Collapse isOpened={this.state.collapseNotes}>
                            <LessonNotes data={this.state.video.description} />
                        </Collapse>

                        {this.state.video.description?.length > 100 &&
                            <Button onClick={this.onCollapse} background="transparent" padding="0" margin="0 0" radius="1em">
                                <HighlightedText margin="1em" size="16px" color="grey" weight="" lineHeight="1.5">
                                    {this.state.collapseNotes ? "SHOW LESS" : "SHOW MORE"}
                                </HighlightedText>
                            </Button>}
                    </Card>


                    <div className="discussionBoard">
                        {
                            getAuthToken() ?
                                <DiscussionBoard videoID={this.state.video.id} /> :
                                <PromptToAuthenticate
                                    message="The discussion board is a great place to ask questions 
                                and learn from other students and the instructor. To get the opportunity to use
                                 the discussion board, kindly get authenticated"/>
                        }
                    </div>
                </section>

            </MediaPageContainer >

        )
    }
}

export default MediaPage;



const MediaPageContainer = styled.div`

    margin: 1em;

    ul {
        list-style-type: square;
        color: black;
    }
    .decorationLi{
        list-style: none;
    }

    .introHeader{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    button{
        display: block;
        font-size: 10px;
    }

    a{
        text-decoration: none;
    }

    .react-player{

    }
    @media only screen and (max-width: 768px){
        margin: 0 0 12vh;

        .react-player{
            height: 45vh !important;
        }

        .discussionBoard{
            ul{
                padding:0;
            }

        }
        

        .lessonNotes{
                margin:0;
                padding:0;
        }
        .introHeader{
            
            h1{
                margin:0.5em;
            }

        }
    }
`

