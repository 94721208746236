import React from 'react';
import { Card } from '../../components/uiComponents/card';
import { HighlightedText, HightlightedSpan } from '../uiComponents/highlightedText';
import { Button } from '../../components/uiComponents/button';
import LikedIcon from '../../assets/icons/liked.svg'
import CommentIcon from '../../assets/icons/comment.svg'
import UserIcon from '../../assets/icons/profile-user.svg'
import { LogoImg } from '../uiComponents/uiElements';
import { numberingShortnerReadable, readableServerError, timeSince } from '../../utils/utilFnx';
import UnLikedIcon from '../../assets/icons/unliked.svg'
import { TextArea } from "../uiComponents/input";
import { Collapse } from 'react-collapse';
import { likeOrUnlikeComment, postDiscussionForVideo } from '../../utils/api/dialogueApi';


class Discussion extends React.Component {
    /* THE MAX DEPTH OF COMMENTING AT LEAST FOR VIDEO IS 2 
    when props come in, copies of likes and user liked are saved
    */

    laudableThreshold = 20

    constructor(props) {
        super();
        this.state = {
            openEmbeddedCommenting: false,
            userLikeWithinComponent: null,
            commentLikes: 0,
            openReplies: false,
            discussion: { message: "" },
            embeddedDiscussions: []
        }

    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.data.id !== this.props.data.id) {
            this.setState({
                 userLikeWithinComponent: prevProps.data.liked_by_user, commentLikes: prevProps.data.likes,
            })
        }
    }

    static getDerivedStateFromProps = (props, state) => {
        // console.log("fsd",state.userLikeWithinComponent, props.data.id, props.innerMostEmbedded);
        if (state.userLikeWithinComponent === null) {
            state =  { ...state, userLikeWithinComponent: props.data.liked_by_user, commentLikes: props.data.likes, }
        }

        if (props.data.id || props.innerMostEmbedded) {
            if (props.innerMostEmbedded && state.discussion.comment_to !== props.innerMostEmbedded) {
                return {
                    ...state,
                    discussion: {
                        ...state.discussion,
                        comment_to: props.innerMostEmbedded,
                        message: `@${props.data.user.first_name} ${props.data.user.last_name}, `
                    },
                }
                

            } else if (!Boolean(props.innerMostEmbedded) && state.discussion.comment_to !== props.data.id) {
                return {
                    ...state,
                    discussion: {
                        ...state.discussion,
                        comment_to: props.data.id,
                        message: `@${props.data.user.first_name} ${props.data.user.last_name}, `
                    },
                }
            }
        }
        
        return null
    }

    onChangeEmbeddedCommenting = (e) => {
        this.setState({
            discussion: { ...this.state.discussion, [e.target.name]: e.target.value },
        })
    }


    submitEmbeddedDiscussion = () => {
        // post discussion

        postDiscussionForVideo(this.props.parentID, this.state.discussion).then((data) => {
            // console.log(data);
            if (this.props.appendEmbeddedToParent) {
                this.props.appendEmbeddedToParent(data)
            } else {
                this.setState({
                    embeddedDiscussions: [data, ...this.state.embeddedDiscussions]
                })
            }
            this.onCancel()
        }).catch((err) => {
            console.log(readableServerError(err));
        })
    }

    onCancel = () => {
        this.setState({
            discussion: { message: "" },
            openEmbeddedCommenting: false,
        })
    }
    appendEmbeddedToParent = (comment) => {
        this.setState({
            embeddedDiscussions: [comment, ...this.state.embeddedDiscussions]
        })
    }

    likeDiscussionInterraction = () => {
        // like discussion 
        likeOrUnlikeComment(this.props.data.id, !this.state.userLikeWithinComponent).then(data => {
            // console.log(data);
            this.setState({
                userLikeWithinComponent: data.liked_by_user,
                commentLikes: data.likes,
            })
        })
    }

    render() {
        // console.log(this.props.data);
        const worthLaudable = this.props.data.likes + (this.props.data.embeddedComments?.length || 0) >= this.laudableThreshold
        return (
            <Card
                display="flex"
                flexFlow="wrap"
                color="#923D41"
                padding="0.5em"
                margin="1em 0"
                space="space-between"
                border={worthLaudable && "0.5px solid #a537fd"}
                background={worthLaudable && "rgba(213, 184, 255, 0.2)"}
            >
                <Card display="flex"
                    background="transparent"
                    width="90%"
                >
                    <LogoImg
                        src={this.props.data.user.profile_pic || UserIcon}
                        alt="commenter"
                        width="40px"
                        height="40px"
                        radius="50%"
                        background="#00b16a50"
                    />
                    <Card
                        margin="0em 1em"
                        background="transparent"
                        width="90%"
                    >
                        <HighlightedText
                            size="16px"
                            weight="bold"
                            lineHeight="1.5">
                            {`${this.props.data.user.first_name} ${this.props.data.user.last_name}`}
                            {worthLaudable &&
                                <HightlightedSpan
                                    margin="0 10px"
                                    color="rgba(142, 68, 173, 1)"
                                    background="rgba(213, 184, 255, 0.4)"
                                    padding="5px 10px"
                                    weight="500"
                                    radius="20px"
                                >
                                    Laudable
                                </HightlightedSpan>}
                        </HighlightedText>
                        <HighlightedText
                            size="16px"
                            weight="500"
                            lineHeight="1.5">
                            {this.props.data.message}
                        </HighlightedText>

                        <Card display="flex" margin="0em 1em" background="transparent">
                            <Button
                                margin="1em"
                                padding="1em"
                                background="transparent"
                                onClick={() => this.likeDiscussionInterraction()}
                            >
                                <img src={this.state.userLikeWithinComponent
                                    ? LikedIcon
                                    : UnLikedIcon}
                                    alt="like"
                                    width={this.state.commentLikes ? "15px" : "20px"}
                                />
                                {this.state.commentLikes && <HighlightedText
                                    margin="-5px -12px 0 10px"
                                    weight="600"
                                    size="12px"
                                    radius="50%"
                                    color="#ed4956"
                                >
                                    {numberingShortnerReadable(this.state.commentLikes)}
                                </HighlightedText>}
                            </Button>

                            <Button
                                margin="1em"
                                padding="10px 10px"
                                background="transparent"
                                onClick={() => this.setState({ openEmbeddedCommenting: !this.state.openEmbeddedCommenting })}
                            >
                                <img src={CommentIcon} alt="comment" width="20px" />

                            </Button>

                        </Card>
                        <Collapse isOpened={this.state.openEmbeddedCommenting}>
                            <TextArea
                                margin="0em 1em"
                                color="black"
                                placeholder={`Responding to a comment by ${this.props.data.user.first_name} ${this.props.data.user.last_name}`}
                                name="message"
                                onChange={this.onChangeEmbeddedCommenting}
                                value={this.state.discussion.message}
                            ></TextArea>


                            <Card display="flex" margin="2em 1em" space="flex-end">
                                <Button margin="1em" background="grey" padding="1em"
                                    onClick={this.onCancel}>
                                    <HighlightedText color="#fff" weight="normal">
                                        Cancel
                                    </HighlightedText>
                                </Button>
                                <Button
                                    margin="1em"
                                    onClick={this.submitEmbeddedDiscussion}
                                >
                                    <HighlightedText color="#fff" >
                                        Post
                                    </HighlightedText>
                                </Button>
                            </Card>

                        </Collapse>

                        <Collapse isOpened={this.state.openReplies}>

                            {

                                [...this.state.embeddedDiscussions, ...this.props.data.embeddedComments || []].map(
                                    (comments, i) =>
                                        <Discussion
                                            key={i}
                                            data={comments}
                                            innerMostEmbedded={this.props.data.id}
                                            appendEmbeddedToParent={this.appendEmbeddedToParent}
                                            parentID={this.props.parentID}
                                        />)

                            }
                        </Collapse>
                        {(this.props.data.embeddedComments || this.state.embeddedDiscussions.length > 0) &&
                            <Button
                                margin="0em"
                                background="transparent"
                                padding="0"
                                onClick={() => this.setState({
                                    openReplies: !this.state.openReplies
                                })}
                            >
                                <HighlightedText color="grey" >
                                    {`${this.state.openReplies ? "Hide" : "Show"} Comments `}
                                    ({`${this.props.data.embeddedComments?.length || 0 + this.state.embeddedDiscussions.length} 
                                ${(this.props.data.embeddedComments?.length || 0 + this.state.embeddedDiscussions.length) > 1
                                            ? "replies" : "reply"}`
                                    }
                                    )
                                </HighlightedText>
                            </Button>
                        }
                    </Card>
                </Card>
                <HighlightedText size="13px" weight="600" color="grey" lineHeight="1.5" margin="0 0.5em">
                    {timeSince(new Date(this.props.data.created_datetime))}
                </HighlightedText>

            </Card>
        )
    }
}


export default Discussion;