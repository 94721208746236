import APIClient from './httpClient';



export const getStudentTopics = async () => {
    const student = new APIClient();
    const endpoint = '/list/';

    const { data } = await student.TopicApi.get(endpoint);
    return data;
}


export const retrieveFullTopic = async (id) => {
    const student = new APIClient();
    const endpoint = `/student/full/${id}/`;

    const { data } = await student.TopicApi.get(endpoint);
    return data;
}

export const getSyllabusList = async (id) => {
    const student = new APIClient();
    const endpoint = `/${id}/sections/list/`;

    const { data } = await student.TopicApi.get(endpoint);
    return data;

}

export const getStudentEnrolledTopics = async () => {
    const student = new APIClient();
    const endpoint = '/user/enrollment/list/';

    const { data } = await student.TopicApi.get(endpoint);
    return data;


}
export const getStudentEnrollmentObjects = async () => {
    const student = new APIClient();
    const endpoint = '/enroll/';

    const { data } = await student.TopicApi.get(endpoint);
    return data;


}


export const enrollInQuiz = async (topicID) => {
    const student = new APIClient();
    const endpoint = '/enroll/';

    const { data } = await student.TopicApi.post(endpoint, {topic: topicID});
    return data;


}


