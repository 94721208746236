import React, { Component } from 'react';
import styled from 'styled-components';
import ArrowRed from '../../assets/icons/arrow_right_red.svg'
import { HighlightedText } from '../uiComponents/highlightedText';
import { Link } from 'react-router-dom'
import { getSyllabusList } from "../../utils/api/topicApi";
import TopicSideBarSectionTile from './topicSideBarSectionTile';

class TopicSidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            topicSyllabus: [],
        }
    }

    componentDidMount = () => {
        this._isMounted = true

        // check whether it is coming from state
        if (this.props.location.state != null && Array.isArray(this.props.location.state)) {
            this._isMounted && this.setState({
                topicSyllabus: this.props.location.state,
            })
        }
        else {
            // if is it not coming from state, fetch from db
            this.getTopicSyllabusData()

        }

    }

    componentWillUnmount = () => {
        this._isMounted = false
    }

    getTopicSyllabusData = () => {
        // console.log(this.props.match.params.topicID);
        if (this.props.match.params.topicID) {
            // to prevent making request with param parameter of `undefined`
            getSyllabusList(this.props.match.params.topicID)
                .then(data => {
                    // console.log(data);
                    this._isMounted && this.setState({
                        topicSyllabus: data,
                    })
                });
        }
    }



    render() {
        // console.log(this.props);
        return (
            <SideBar className={this.props.className} onClick={() => this.props.onClick && this.props.onClick()}>
                <HighlightedText size="25px" textAlign="center" color="#923D41" margin="0.5em 0">
                    Skideo
                </HighlightedText>
                <hr />

                <div className="barBody">

                    {this.state.topicSyllabus.map((section, i) =>
                        <TopicSideBarSectionTile
                            key={i}
                            section={section}
                            topicID={this.props.match.params.topicID}
                            contentID={this.props.match.params.contentID}
                            contentType={this.props.match.params.contentType}
                        />
                    )}
                </div>

                <div className="bottomDiv">

                    <Link to="/home" className="exitTopic" data-testid="close-topic-link">
                        <b>Close </b><img src={ArrowRed} alt="close topic link" />
                    </Link>
                </div>

            </SideBar>
        )
    }
}

export default TopicSidebar


const SideBar = styled.div`

    min-width: 15%;
    height: 100vh;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #ffffff;
    overflow: scroll;
    overflow-y:scroll;
    overflow-x:hidden;    

    
    .bottomDiv{
        position: absolute;
        bottom: 0%;
        text-align-last: center;
        padding: 0.5em;
        margin: 0.5em;

        .exitTopic{
            background-color: #fff;
            color: #923d41;
            border-radius: 7px;
            padding: 8px 15px;
            border: 1px solid #923d41;
            text-decoration: none;
        }
    }

    .barBody{
        display: block;
        margin: 10px auto 5em;
    }

    @media only screen and (max-width: 768px){
        .bottomDiv{
            bottom: 5vh;
        }

    }


`
