
import React from 'react';
import { getBackgroundFiller } from '../../utils/utilFnx';
import { Card } from '../uiComponents/card'
import { HighlightedText } from '../uiComponents/highlightedText';


class BackgroundFiller extends React.Component {

    render =()=>{
            const withGradient = this.props.data.labels.length && this.props.data.learning_goals.length
            const color = getBackgroundFiller(this.props.data.id, withGradient)
            return (
                // `topicImage` is a class specified in the parent class
                <Card className={`topicImage coloredBackground ${withGradient ? "withGradientBackground" : ""}`}
                    background= {color[0]}
                    gradientBackground={withGradient && color[1]}
                    textAlign="center" display="flex" alignItems="center"
                    radius="2px 2px 0 0"
                >
                    <HighlightedText color="white" display="block" margin="10px auto">
                        {this.props.data.title}
                    </HighlightedText>
                </Card>
            )
    }
}

export default BackgroundFiller