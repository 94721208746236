
import React, { Component } from 'react';
import styled from 'styled-components';
import NoEnrolls from '../../../assets/illustrations/brainstorming.svg'
import { HighlightedText } from '../../../components/uiComponents/highlightedText';
import { getStudentTopics, getStudentEnrolledTopics } from "../../../utils/api/topicApi";
import GenericTopicCard from "../../../components/topic/ genericTopicCard";
import { readableServerError } from '../../../utils/utilFnx';
import { getAuthToken } from '../../../utils/storage/auth';
import SnackBar from 'my-react-snackbar';
import EnrolledTopicCard from '../../../components/topic/enrolledTopicCard';
import GridLoader from "react-spinners/GridLoader";
import { css } from "@emotion/react";




class CourseModules extends Component {

    constructor(props) {
        super();
        this.state = {
            suggestedTopics: [],
            enrolledTopics: [],
            loadingSuggested: false,
            loadingEnrolled: false,
            snackMessage: { type: null, message: null },
        }
    }


    componentDidMount = () => {
        this._isMounted = true
        this.getSuggestedTopicsData()
        if (getAuthToken()) {
            this.getStudentEnrolledCoursesData()
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false
    }


    getStudentEnrolledCoursesData = () => {
        this._isMounted && this.setState({
            loadingEnrolled: true
        })
        getStudentEnrolledTopics().then(data => {

            this._isMounted && this.setState({
                loadingEnrolled: false,
                enrolledTopics: data,
            })
        }).catch((err) => {
            this.catchLogic(err)
            this._isMounted && this.setState({
                loadingEnrolled: false,
            })
        });
    }

    catchLogic =(err) =>{
        const errorMessage = readableServerError(err)
        if (String(errorMessage).includes("Invalid token")) {
            this._isMounted && this.setState({
                snackMessage: { type: "warning", message: "Authorization is invalid. Login to get authorized." },
            })
        }
        else {
            this._isMounted && this.setState({
                snackMessage: { type: "error", message: errorMessage },
            })

        }
    }

    getSuggestedTopicsData = () => {
        this._isMounted && this.setState({
            loadingSuggested: true
        })
        getStudentTopics()
            .then(data => {
                // console.log(data)
                this._isMounted && this.setState({
                    loadingSuggested: false,
                    suggestedTopics: data
                })
            }).catch((err) => {
                this.catchLogic(err)
                this._isMounted && this.setState({
                    loadingSuggested: false,
                })
            });
    }




    render = () => {
        // console.log(this.state.enrolledTopics);
        return (
            <TopicContainer>
                <SnackBar
                    open={this.state.snackMessage.message}
                    message={this.state.snackMessage.message}
                    type={this.state.snackMessage.type}
                    position="bottom-center"
                    timeout={this.state.snackMessage.type !== "warning" && 5000}
                    noLabel="Cancel"
                    onNo={()=>{}}
                    yesLabel={this.state.snackMessage.type === "warning" && 'Fix'}
                    onYes={() => { 
                        if (this.state.snackMessage.type === "warning"){
                            localStorage.removeItem("a_bearer")
                            window.location.reload()
                        }
                    }}
                />
                {this.state.loadingSuggested && this.state.loadingEnrolled ?
                    <GridLoader color="#923D41" css={LoadingAnimationStyle} margin="10px" />
                    : <>
                        {getAuthToken()
                            ?
                            this.state.loadingEnrolled ?
                                <GridLoader color="#923D41" css={LoadingAnimationStyle} margin="10px" />
                                :
                                <>
                                    <HighlightedText className="titleH1" margin="1em"> Topics you are taking</HighlightedText>
                                    {this.state.enrolledTopics.length === 0 ?
                                        <div className="noEnrolls">
                                            <img src={NoEnrolls} alt="no enrolls" width="300px" />
                                            <span>
                                                No current enrollments.
                                            </span>
                                        </div>
                                        : <div className="topicListDiv">
                                            {this.state.enrolledTopics.map((enrolledTopic, i) => <EnrolledTopicCard key={i} data={enrolledTopic} />)}
                                        </div>
                                    }</>
                            : null
                        }

                        <HighlightedText margin="1em" className="titleH1">Suggested Topics</HighlightedText>
                        <div className="topicListDiv">

                            {this.state.loadingSuggested ?
                                <GridLoader color="#923D41" css={LoadingAnimationStyle} margin="10px" />
                                :
                                this.state.suggestedTopics.map((topic, i) => <GenericTopicCard key={i} data={topic} />)
                            }

                        </div>
                    </>}
            </TopicContainer>

        )
    }

}

export default CourseModules

const TopicContainer = styled.div`

    margin: 1em 1em 15vh 1em;

    .noEnrolls{
        text-align: center;
        display: block;
        margin: 1em auto;

        span{
            width: fit-content;

            padding: 7px 15px;
            color: #500a1c;
            background: rgba(241, 169, 160, 0.5);
            margin: 10px auto;
            display: block;
            border-radius: 1em;
        }
    }

    .topicListDiv{
        display: flex;
        flex-flow: wrap;
        justify-content: flex-start;

    }

    a{
        text-decoration: none;
    }

    @media only screen and (max-width: 768px){

        .titleH1{
            margin: 1em 0 0.3em;
            width: 100%;
        }
    }
`




const LoadingAnimationStyle = css`
    display: block;
    margin: 20px auto;
`;