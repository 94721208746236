import axios from 'axios';

/* 
* if going to production, change the `url`
 in APIClient to backend url to the right one in an .env file. THis is gitignored
*/

class APIClient {

  url = process.env.REACT_APP_BACKEND_URL;


  accountApi = axios.create({
    baseURL: `${this.url}/account/`,
  });


  accountAuthApi = axios.create({
    baseURL: `${this.url}/account/`,
    headers: {
      Authorization: Boolean(localStorage.getItem("a_bearer")) ? `Token ${JSON.parse(localStorage.getItem("a_bearer"))}` : null
    }

  });


  NoteApi = axios.create({
    baseURL: `${this.url}/note/`,
    headers: {
      Authorization: Boolean(localStorage.getItem("a_bearer")) ? `Token ${JSON.parse(localStorage.getItem("a_bearer"))}` : null
    }
  });
  
  TopicApi = axios.create({
    baseURL: `${this.url}/topic/`,
    headers: {
      Authorization: Boolean(localStorage.getItem("a_bearer")) ? `Token ${JSON.parse(localStorage.getItem("a_bearer"))}` : null
    }

  });

  QuizApi = axios.create({
    baseURL: `${this.url}/quiz/`,
    headers: {
      Authorization: Boolean(localStorage.getItem("a_bearer")) ? `Token ${JSON.parse(localStorage.getItem("a_bearer"))}` : null
    }
  });
  VideoApi = axios.create({
    baseURL: `${this.url}/video/`,
    headers: {
      Authorization: Boolean(localStorage.getItem("a_bearer")) ? `Token ${JSON.parse(localStorage.getItem("a_bearer"))}` : null
    }
  });

  DialogueApi = axios.create({
    baseURL: `${this.url}/dialogue/`,
    headers: {
      Authorization: Boolean(localStorage.getItem("a_bearer")) ? `Token ${JSON.parse(localStorage.getItem("a_bearer"))}` : null
    }
  });

  SubscriberApi = axios.create({
    baseURL: `${this.url}/subscribe/`,
    headers: {
      Authorization: Boolean(localStorage.getItem("a_bearer")) ? `Token ${JSON.parse(localStorage.getItem("a_bearer"))}` : null
    }
  });


}

export default APIClient;
