import APIClient from './httpClient';



export const retrieveVideo = async (videoID) => {
    const api = new APIClient();
    const endpoint = `/retrieve/${videoID}/`;

    const { data } = await api.VideoApi.get(endpoint);
    return data;
}

export const getVideoInfoFromSyllabusContent = async (syllabusContentID) => {
    const api = new APIClient();
    const endpoint = `/retrieve/syllabus/content/${syllabusContentID}/`;
    
    const { data } = await api.VideoApi.get(endpoint);
    return data;
}

export const updateVideoInterraction = async (videoID, interractionData) => {
    const api = new APIClient();
    const endpoint = `/interraction/${videoID}/`;
    
    const { data } = await api.VideoApi.put(endpoint, interractionData);
    return data;
}

export const createVideoLog = async (videoID) => {
    const api = new APIClient();
    const endpoint = `/${videoID}/logs/create/`;
    
    const { data } = await api.VideoApi.post(endpoint);
    return data;
}

export const createVideoLogInterraction = async (videoLogID, videoLogInterractionData) => {
    const api = new APIClient();
    const endpoint = `/logs/${videoLogID}/interraction/create/`;
    
    const { data } = await api.VideoApi.post(endpoint, videoLogInterractionData);
    return data;
}