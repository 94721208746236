
import React, { Component } from 'react';
import styled from 'styled-components';
import GeneralSideBar from '../components/sideBar/generalSideBar'
import TopicSideBar from '../components/sideBar/topicSideBar'
import AdminTopBar from '../components/topBar/topBar'
import { Switch, Route, Redirect } from 'react-router-dom';
import HomePage from '../pages/authorized/home/home'
import TopicOverview from '../pages/authorized/topicOverview/topicOverview'
import StudentQuizPage from '../pages/authorized/quiz/quiz'
import MediaPage from "../pages/authorized/media/media";
import FeedBack from '../pages/authorized/feedback/feedback'
import SettingsPage from "../pages/authorized/settings/settings";
import { TOPIC_SIDEBAR_REGEX } from '../utils/constants';
import NotePage from '../pages/authorized/note/note';
import { isLoggedIn } from '../utils/api/accountApi';
import { getItemFromLocal } from '../utils/utilFnx';



class Authorized extends Component {
    re = TOPIC_SIDEBAR_REGEX;

    constructor(props) {
        super()
        this.state = {
            isAuthenticated: null,
        }
    }


    componentDidMount = () => {
        // condition to check for logged in user if they failed login pass and do not also have a token
        if (!getItemFromLocal("authPass") && getItemFromLocal("a_bearer")){
            this.loggedIn()
        }
    }

    loggedIn = () => {
        isLoggedIn().then(data => {
            if (!data.response) {
                this.props.history.push("/auth/login");
            }

        }).catch((e) => {
            this.props.history.push("/auth/login");

            // console.log(e.response);
            // console.log(
            //     "You're not logged in.😠"
            // );
        });
    }


    render() {

        return (
            <UserContainer>
                {/* <AdminSideBar current={page_url} /> */}
                {this.re.test(this.props.location.pathname) ?
                    (
                        <Route path={`${this.props.match.path}topic/:topicID/main/:contentType/:contentID`}
                            render={(props) =>
                                <TopicSideBar {...props}
                                    className="resp__bo"
                                />}
                        />
                    )
                    :
                    <GeneralSideBar />
                }
                <UserBody>
                    <AdminTopBar {...this.props} />
                    {
                        <Switch>
                            <Route exact path={`${this.props.match.path}home`} component={HomePage} />
                            <Route path={`${this.props.match.path}settings`} component={SettingsPage} />
                            <Route exact path={`${this.props.match.path}topic/:topicID/overview`} component={TopicOverview} />
                            <Route exact path={`${this.props.match.path}topic/:topicID/main/video/:videoID`} component={MediaPage} />
                            <Route exact path={`${this.props.match.path}topic/:topicID/main/note/:noteID`} component={NotePage} />
                            <Route path={`${this.props.match.path}topic/:topicID/main/quiz/:quizID`} component={StudentQuizPage} />
                            <Route exact path={`${this.props.match.path}feedback`} component={FeedBack} />

                            <Redirect to={{ pathname: "/home" }} />

                        </Switch>
                    }
                </UserBody>
            </UserContainer>

        )
    }
}

export default Authorized;



const UserContainer = styled.div`
    width:100%;
    display:flex;
    position: fixed;
    top: 0;
    bottom:0;

`
const UserBody = styled.div`
    background-color: #F7F9FB;
    min-width: 85%;
    min-height: 90vh;
    overflow: scroll;
    overflow-y:scroll;
    overflow-x:hidden;

    @media only screen and (max-width: 768px){
        width: 100%;
    }

`
