

import React from 'react';
import { HighlightedText } from '../uiComponents/highlightedText';
import styled from 'styled-components';
import QuizIcon from '../../assets/icons/quiz.svg'
import { durationReadableFromNumer } from '../../utils/utilFnx';
import VideoIcon from '../../assets/icons/video_wine.svg'
import NoteIcon from '../../assets/icons/notes-wine.svg'
import { Link } from 'react-router-dom'



class SyllabusContentTile extends React.Component {

    render() {
        return (
            <SyllabusContentContainer
                to={{
                    pathname: `/topic/${this.props.topicID}/main/${this.props.data.content_type}/${this.props.data.content?.id}`,
                }}
                background={this.props.data.content?.completed_by_user ? "rgba(0, 230, 64, 0.3)" : undefined}
            >
                <HighlightedText size="16px" weight="500" color="#923D41" lineHeight="1.5" width="80%">
                    {this.props.data.content?.title}
                </HighlightedText>
                <HighlightedText size="13px" weight="600" color="grey" lineHeight="1.5" margin="0 0.5em">
                    {durationReadableFromNumer(this.props.data.content?.duration)}
                </HighlightedText>
                <img
                    src={
                        this.props.data.content_type === "quiz"
                            ? QuizIcon :
                            this.props.data.content_type === "video"
                                ? VideoIcon : NoteIcon
                    }
                    alt={this.props.data.content_type || "content type"
                    }
                    width="20px" />

            </SyllabusContentContainer>

        )
    }
}

export default SyllabusContentTile

const SyllabusContentContainer = styled(Link)`
    display: flex;
    color: #923D41;
    border-left: 2px solid #923D41;
    padding: 0.5em !important;
    margin: 1em 0 !important;
    width: 100%;
    justify-content: space-between;
    background-color: ${({ background }) => background || "#ffffff"} !important;


`