
import React from 'react';
import styled from 'styled-components';
import { HighlightedText } from '../../../components/uiComponents/highlightedText';
import { Card } from "../../../components/uiComponents/card";
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import Account from './account';
import Profile from './profile';
import Security from './security';




class Settings extends React.Component {

    constructor(props) {
        super();

    }


    componentDidMount = () => {

    }


    render = () => {
        // console.log(this.props.match.path);
        return (
            <SettingsContainer>

                <HighlightedText color="#923D41" size="30px" margin="1em">
                    Account Settings
                </HighlightedText>
                <Card display="flex" margin="1em" className="tab">
                    <NavLink
                        activeClassName="active"
                        to="/settings/account"
                        data-testid="accountsettingslink">
                        <HighlightedText size="15px" textAlign="center" color="#923D41" margin="1em">
                            Account
                        </HighlightedText>

                    </NavLink>
                    <NavLink
                        activeClassName="active"
                        to="/settings/profile"
                        data-testid="profilesettingslink">
                        <HighlightedText size="15px" textAlign="center" color="#923D41" margin="1em">
                            Profile
                        </HighlightedText>

                    </NavLink>
                    <NavLink
                        activeClassName="active"
                        to="/settings/security"
                        data-testid="securitysettingslink">
                        <HighlightedText size="15px" textAlign="center" color="#923D41" margin="1em">
                            Security and Privacy
                        </HighlightedText>

                    </NavLink>
                </Card>

                <Switch>

                    <Route exact path={`${this.props.match.path}/account`} component={Account} />
                    <Route exact path={`${this.props.match.path}/profile`} component={Profile} />
                    <Route exact path={`${this.props.match.path}/security`} component={Security} />
                    <Redirect to={{ pathname: "/settings/account" }} />

                </Switch>

            </SettingsContainer>

        )
    }

}

export default Settings




const SettingsContainer = styled.div`
    margin: 1em;

    a{
        text-decoration: none;
        margin: 10px 20px;
        
        border-radius: 10px;

        &.active{
            background-color: rgba(232, 232, 232, 0.6)
        }
    }

    @media only screen and (max-width: 768px){
        display: block;
        margin: 5% 2%;
        width: auto;
        h1{
            width: 90%;
            margin: 2%;
        }
        .tab{
            padding: 2%;
            margin: 2%;
            align-items: center;
            justify-content: space-between;
            a{
                
                padding: 2% 4%;
            }
        }
    }
`