import styled from 'styled-components';

export const Button = styled.button`
display: ${({ display }) => display};
color: ${({ color }) => color || "#ffffff"};
background-color: ${({ background }) => background || "#923d41"};
border: ${({border}) => border || 0};
padding: ${({ padding }) => padding || "7px 53px"};
margin: ${({ margin }) => margin || "0px 0px"};
border-radius: ${({radius}) => radius || "2px"};
width: ${({width}) => width || "auto"};
height: ${({height}) => height || "auto"};
`;

