import React from 'react';
import styled from 'styled-components';
import { Card } from '../../components/uiComponents/card';
import { HighlightedText } from '../uiComponents/highlightedText';
import { TextArea } from "../uiComponents/input";
import { Button } from '../../components/uiComponents/button';
import UserIcon from '../../assets/icons/profile-user.svg'
import Discussion from "./discussion";
import { getItemFromLocal, readableServerError } from '../../utils/utilFnx';
import { LogoImg } from "../uiComponents/uiElements";
import { getDiscussionForVideo, postDiscussionForVideo } from '../../utils/api/dialogueApi';


class DiscussionBoard extends React.Component {
    constructor(props) {
        super();
        this.state = {
            showMore: false,
            discussion: { message: "" },
            discussionBoard: [],
            discussionNumber: 0
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.videoID !== prevProps.videoID) {
            this.onGetVideoDiscussion()

        }
    }

    onMainDiscussionChange = (e) => {
        this.setState({
            discussion: { ...this.state.discussion, [e.target.name]: e.target.value, }
        })
        // console.log(this.state.discussion.message);
    }


    onGetVideoDiscussion = () => {
        // post discussion
        getDiscussionForVideo(this.props.videoID).then((data) => {
            // console.log(data);
            // arrange the comments in their nexted order
            // loop through the arrays 
            const discussionNumber = data.length
            data = this.organizingComments(data)
            // console.log(data);
            this.setState({
                discussionBoard: data,
                discussionNumber: discussionNumber
            })
        }).catch((err) => {
            console.log(readableServerError(err));
        })

    }

    onPostVideoDiscussion = () => {
        // post discussion
        postDiscussionForVideo(this.props.videoID, this.state.discussion).then((data) => {
            this.setState({
                discussionBoard: [data, ...this.state.discussionBoard]
            })
            this.onCancel()
        }).catch((err) => {
            console.log(readableServerError(err));
        })
    }

    organizingComments = (comments) => {
        let parentComments = comments.filter(({ comment_to }) => comment_to === null)
        comments.splice(0, parentComments.length)
        for (let i = 0; i < comments.length; i++) {
            const c = comments[i];
            let parent = parentComments.find(({ id }) => id === c.comment_to)
            parent.embeddedComments = parent.embeddedComments ? [...parent.embeddedComments, c] : [c]
        }
        return parentComments
    }

    onCancel = () => {
        this.setState({ discussion: { message: "" } })
    }


    render() {
        // console.log(this.props.videoID);

        return (
            <DiscussionBoardContainer>

                <HighlightedText margin="1em" size="16px" weight="" lineHeight="1.5">
                    DISCUSSION BOARD {this.state.discussionNumber === 0 ? "(Be the first)": `(${this.state.discussionNumber})`}
                </HighlightedText>
                <Card>
                    <Card display="flex" margin="2em 1em">
                        <LogoImg
                            src={getItemFromLocal("profile_pic") || UserIcon}
                            alt="user"
                            width="50px"
                            height="50px"
                            radius="50%"
                            background="#00b16a50"
                        />
                        <TextArea
                            margin="0em 1em"
                            color="black"
                            placeholder="Questions, comments and lesson discussion"
                            name="message"
                            onChange={this.onMainDiscussionChange}
                            value={this.state.discussion.message}
                        ></TextArea>
                    </Card>

                    <Card display="flex" margin="2em 1em" space="flex-end" flexFlow="wrap">
                        <Button margin="1em" background="grey" padding="1em"
                            onClick={this.onCancel}>
                            <HighlightedText color="#fff" weight="normal">
                                Cancel
                            </HighlightedText>
                        </Button>
                        <Button
                            margin="1em"
                            onClick={this.onPostVideoDiscussion}
                        >
                            <HighlightedText color="#fff" >
                                Post
                            </HighlightedText>
                        </Button>
                    </Card>

                </Card>
                <ul className="decorationLi">
                    {
                        this.state.discussionBoard.map((discussion, i) =>
                            <Discussion
                                key={i}
                                data={discussion}
                                parentID={this.props.videoID}
                            />)
                    }
                </ul>

            </DiscussionBoardContainer>

        )
    }
}

export default DiscussionBoard;

const DiscussionBoardContainer = styled.div`
    margin: 1em;
    padding: 1em;
    border-radius: 0.5em;
    background-color: white;

    @media only screen and (max-width: 768px){
        padding: 1em 0;
        img{
            width: 30px;
            height: 30px;
        }
    }
`