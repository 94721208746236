
import React from 'react';
import { HighlightedText } from '../uiComponents/highlightedText';

import { Card } from "../uiComponents/card";
import { Button } from '../uiComponents/button';
import { Collapse } from 'react-collapse';
import SyllabusContentTile from './syllabusContent';

class SyllabusSectionTile extends React.Component {

    constructor(props) {
        super();
        this.state = {
            collapse: false,
            loading: false,
            contents: [],
            snackMessage: { type: null, message: null },
        }
    }
    componentDidMount = () => {

    }

    render = () => {
        // console.log(this.props.data);
        // console.log(this.props.data.syllabus_contents[0]?.content);

        return (
            <Card border="1px solid grey" background={!this.state.collapse && "#fafbfc"} radius="0.5em" margin="1em">


                <Button
                    onClick={() => this.setState({ collapse: !this.state.collapse })}
                    background="transparent"
                    padding="0"
                    margin="0"
                    radius="1em"
                >

                    <HighlightedText margin="1em" size="16px" weight="" lineHeight="1.5">
                        {this.props.data.title}
                    </HighlightedText>
                </Button>
                <Collapse isOpened={!this.state.collapse}>
                    <ul>
                        {this.props.data.syllabus_contents?.map((syllabusContent, i) =>
                            <li key={i}>
                                <SyllabusContentTile data={syllabusContent} topicID={this.props.topicID}/>
                            </li>
                        )}

                    </ul>
                </Collapse>

            </Card>

        )
    }

}

export default SyllabusSectionTile