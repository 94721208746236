import { HOMEPAGE_FILLER_CARDS_WITHOUT_GRADIENT, HOMEPAGE_FILLER_CARDS_WITH_GRADIENT_AND_FALLBACK } from "./constants";

export const setItemToLocal = (name, data) => {
    localStorage.setItem(name, JSON.stringify(data));
}

export const getItemFromLocal = (name) => {
    return localStorage.getItem(name) ? JSON.parse(localStorage.getItem(name)) : null;
}


export const appendItemToLocalArray = (name, data) => {
    const cached = getItemFromLocal(name) || []
    setItemToLocal(name, [data, ...cached])
}


export const readableServerError = (err) => {
    return err.response
        ? err.response.status === 500
            ? "The server had an issue with your request"
            : err.response.status === 404 ?
                "Resource not found"
                : typeof (err.response.data) === "string" ?
                    err.response.data
                    : Array.isArray(err.response.data) ?
                        err.response.data.map(e => {
                            if (typeof (e) === "string") {
                                return e
                            } else {
                                return Object.entries(e).map(ee =>
                                    `${ee[0].toLocaleUpperCase()}-${ee[1]} \n`)

                            }
                        })
                        :
                        Object.entries(err.response.data).map(e =>
                            `${e[0].toLocaleUpperCase()}-${e[1]} \n`)
        : err.message ?? "Unknown error. Kindly report for fixing"
}


export const enrollment = (enrolls) => {
    return `${numberingShortnerReadable(enrolls)} enrolled`

}

export function timeSince(date) {
    // console.log(date);
    if (typeof (date) === "string") {
        date = new Date(date)
    }

    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
}

export const durationReadable = (duration) => {
    if (!Boolean(duration)) {
        return ""
    }

    duration = duration.split(":")
    duration[2] = parseFloat(duration[2]).toFixed(2)
    if (parseInt(duration[0]) > 0) {
        duration[0] = parseInt(duration[0]).toString() // hh:mm:ss or 
        return duration.join(":")
    }
    else {
        duration[1] = parseInt(duration[1]).toString() // mm:ss 
        return duration.slice(1, 3).join(":")
    }
}

export const pruneReturnObject = (data, depth) => {
    if (Array.isArray(data)) {
        if (depth === 1) {
            return data.filter(d => Boolean(d))
        }

    }

}


export const convertTimeDelta = (dateTimeHigh, dateTimeLow) => {
    // returns [timedelta, unit]

    const timeDelta = ((new Date(dateTimeHigh) - new Date(dateTimeLow)) / 1000)

    if (timeDelta <= 60) {
        return [timeDelta.toFixed(2), "secs"]
    } else if ((timeDelta / 60) <= 60) {
        return [(timeDelta / 60).toFixed(2), "mins"]
    } else if ((timeDelta / 3600) <= 24) {
        return [(timeDelta / 3600).toFixed(2), "hrs"]
    } else {
        return [(timeDelta / 86400).toFixed(2), "days"]
    }

}


export const convertDurationStringToSeconds = (duration) => {
    if (typeof (duration) === "number") {
        return duration
    }
    duration = duration.split(":").reverse()
    var seconds = 0
    duration.map((value, i) => {
        if (i > 0) {
            seconds += parseInt(value) * 60 ** i
        } else {
            seconds += parseFloat(value) * 60 ** i
        }
        return []
    })
    return seconds
}

export const durationReadableFromNumer = (duration) => {

    if (typeof (duration) === "string") {
        duration = convertDurationStringToSeconds(duration)
    }

    if (!Boolean(duration)) {
        return ""
    }
    var secs = Math.floor(duration % 60);
    if (duration / 60 < 1) {
        return secs + " secs";
    }
    var mins = Math.floor(duration / 60);
    if (mins < 60) {
        return mins + ":" + secs + " mins"
    }
    else {
        mins = mins % 60
        return Math.floor(duration / 3600) + ":" + mins + ":" + secs + " hrs"
    }

}



export const numberingShortnerReadable = (number) => {

    if (number >= 1e6) {
        return `${(number / 1e6).toFixed(1)}M`
    } else if (number >= 1e3) {
        return `${(number / 1e3).toFixed(1)}K`
    } else {
        return String(number)
    }

}



export const pruneDataObject = (data) => {
    let returnData = {}
    for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
            const element = data[key];
            if (Boolean(element)) {
                // console.log(element);
                returnData[key] = element
            }
        }
    }
    return returnData
}



export const packageFormDataSubmission = (data, field, sendField) => {
    let bodyFormData = new FormData();

    Object.entries(data).forEach(kvPair => {
        if (kvPair[1] !== null && !(Array.isArray(kvPair[1]) && kvPair[1].length === 0)) {
            if (Array.isArray(kvPair[1])) {
                bodyFormData.append(kvPair[0], JSON.stringify(kvPair[1]))

            } else {
                bodyFormData.append(kvPair[0], kvPair[1])
            }

        }
    })
    if (data[field]) {
        bodyFormData.append(sendField, data[field]);
    }
    return bodyFormData
}

export const getBackgroundFiller = (id, withGradient) => {
    if (withGradient) {
        return HOMEPAGE_FILLER_CARDS_WITH_GRADIENT_AND_FALLBACK[id % HOMEPAGE_FILLER_CARDS_WITH_GRADIENT_AND_FALLBACK.length]

    } else {
        return [HOMEPAGE_FILLER_CARDS_WITHOUT_GRADIENT[id % HOMEPAGE_FILLER_CARDS_WITHOUT_GRADIENT.length]]
    }  
}