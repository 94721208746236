
import APIClient from './httpClient';


export const getQuizFromSyllabusContent = async (syllabusContent) => {
    const quiz = new APIClient();
    const endpoint = `/syllabus/content/${syllabusContent}/exam/`;

    const { data } = await quiz.QuizApi.get(endpoint);
    return data;

}


export const retrieveQuiz = async (quizID) => {
    const quiz = new APIClient();
    const endpoint = `/${quizID}/exam/`;

    const { data } = await quiz.QuizApi.get(endpoint);
    return data;

}

export const createQuizSummary = async (quizID) => {
    const quiz = new APIClient();
    const endpoint = `/summary/`;

    const { data } = await quiz.QuizApi.post(endpoint, { "quiz": quizID });
    return data;

}

export const retrieveQuizSummary = async (quizSummaryID) => {
    const quiz = new APIClient();
    const endpoint = `/summary/${quizSummaryID}/`;

    const { data } = await quiz.QuizApi.get(endpoint);
    return data;

}

export const getQuizInfoFromSyllabusContent = async (syllabusContentID) => {
    const quiz = new APIClient();
    const endpoint = `retrieve/syllabus/content/${syllabusContentID}/`;


    const { data } = await quiz.QuizApi.get(endpoint);
    return data

}



export const postAnswersWithSummaryID = async (
    answers, summaryID
) => {
    const quiz = new APIClient();
    const endpoint = `/summary/${summaryID}/answers/`;
    const { data } = await quiz.QuizApi.post(endpoint, answers);
    return data
}

export const postAnswersWithQuizID = async (
    answers, quizID
) => {
    const quiz = new APIClient();
    const endpoint = `/${quizID}/answers/`;
    const { data } = await quiz.QuizApi.post(endpoint, answers);
    return data
}
