

import React from 'react';
import { HighlightedText } from '../../../components/uiComponents/highlightedText';
import { retrieveQuiz } from '../../../utils/api/quizApi';
import { getAuthToken } from '../../../utils/storage/auth';
import PromptToAuthenticate from '../../../components/uiComponents/promptToAuthenticate';
import { Redirect, Route, Switch } from 'react-router-dom';
import Exam from './exam';
import QuizSummary from "./quizSummary";


class Quiz extends React.Component {

    constructor(props) {
        super();
        this.state = {
            authenticated: getAuthToken(),
            quiz: {},
            answers: [],
            rightAnswers: false,
        }
    }

    componentDidMount = () => {
        this.getQuizData()
    }
    componentDidUpdate(prevProps, prevState) {
        // console.log(this.props.match.params.quizID, prevProps.match.params.quizID);

        if (this.props.match.params.quizID !== prevProps.match.params.quizID) {
            this.getQuizData()
            this.setState({
                answers: [],
            })
        }

    }

    getQuizData = () => {
        if (this.state.authenticated){
            
            retrieveQuiz(this.props.match.params.quizID).then((data) => {
                this.setState({
                    quiz: data,
                })
            })
        }
    }


    updateUserSummaries = (summary) => {
        // this update would be just for just the user summaries. 
        // takes the summary as the parameter 
        
        let userSummaries = this.state.quiz.user_summaries
        const existingSummary = userSummaries.findIndex(({ id }) => id === summary.id); // NOTE: { id } extracts the id field
        
        if (existingSummary !== -1) {
            // then updates if there is one with the id available 
            userSummaries[existingSummary] = summary
        } else {
            // else, it add to the list
            userSummaries.push(summary)
        }
        this.setState({
            quiz: {...this.state.quiz, user_summaries: userSummaries}
        })
        
    }


    render() {
        // console.log(this.state.quiz);
        if (this.state.authenticated) {

            return (
                <div>
                    <HighlightedText color="#923D41" margin="1em">{this.state.quiz.title || "Quiz"}</HighlightedText>

                    <Switch>
                        <Route
                            exact path={`${this.props.match.path}/summaries`}
                            render={(props) =>
                                <QuizSummary {...props}
                                    quiz={this.state.quiz}
                                />}
                        />
                        <Route
                            exact path={`${this.props.match.path}/exam`}
                            render={(props) =>
                                <Exam {...props}
                                    updateUserSummaries={this.updateUserSummaries}
                                    quiz={this.state.quiz}
                                />}
                        />

                        <Redirect to={{ pathname: `${this.props.match.url}/summaries` }} />

                    </Switch>

                </div>
            )
        } else {
            return (
                <PromptToAuthenticate />
            )
        }

    }
}

export default Quiz;
