import APIClient from './httpClient';

export const postFeedback = async (feedback) => {
    /* post feedback
     */
    const api = new APIClient();
    const endpoint = '/feedback/';

    const { data } = await api.DialogueApi.post(endpoint, feedback);
    return data;

}


export const postDiscussionForVideo = async (videoID, discussionData) => {
    const api = new APIClient();
    const endpoint = `/video/${videoID}/comments/`;

    const { data } = await api.DialogueApi.post(endpoint, discussionData);
    return data;
}

export const getDiscussionForVideo = async (videoID) => {
    const api = new APIClient();
    const endpoint = `/video/${videoID}/comments/`;

    const { data } = await api.DialogueApi.get(endpoint);
    return data;
}

export const likeOrUnlikeComment = async (commentID, liked) => {
    const api = new APIClient();

    if (liked) {
        const endpoint = `/like/${commentID}/comments/`;
        const { data } = await api.DialogueApi.post(endpoint);
        return data;
    }else{
        const endpoint = `/unlike/${commentID}/comments/`;
        const { data } = await api.DialogueApi.post(endpoint);
        return data;
    }
}
