import styled from 'styled-components';



export const HighlightedText = styled.h1`
    font-size: ${({ size }) => size};
    font-weight: ${({ weight }) => weight || "bold"};
    font-style: ${({ fontStyle }) => fontStyle || "normal"};
    font-stretch: normal;
    text-transform: ${({ textTransform }) => textTransform};;
    line-height: ${({ lineHeight }) => lineHeight || "normal"};
    letter-spacing: normal;
    color: ${({ color }) => color || "#000000"};
    margin: ${({ margin }) => margin || "0px"};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    align-items: ${({ alignItems }) => alignItems};
    display: ${({ display }) => display};
    justify-content: ${({space}) => space};
    background-color: ${({ background }) => background};
    border-radius: ${({ radius }) => radius};
    padding: ${({ padding }) => padding};
    align-self: ${({ alignSelf }) => alignSelf};
    text-align: ${({ textAlign }) => textAlign};

    .center-text{
        text-align: center;
    }

    .right-text{
        float: right;
    }

    /*@media only screen and (max-width: 768px){
        font-size:medium
    }*/

`

export const HightlightedSpan = styled.span`
    font-size: ${({ size }) => size};
    font-weight: ${({ weight }) => weight || "bold"};
    font-style: ${({ fontStyle }) => fontStyle || "normal"};
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${({ color }) => color || "#000000"};
    background-color: ${({ background }) => background};
    margin: ${({ margin }) => margin || "0px"};
    padding: ${({ padding }) => padding};
    display: ${({ display }) => display};
    border-radius: ${({ radius }) => radius};

    .center-text{
        text-align: center;
    }

    .right-text{
        float: right;
    }

`
