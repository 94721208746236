

import React, { Component } from 'react';
import styled from 'styled-components';
import { HighlightedText } from '../../../components/uiComponents/highlightedText';
import { Card } from "../../../components/uiComponents/card";
import { Button } from '../../../components/uiComponents/button';
import { Input, TextArea } from '../../../components/uiComponents/input';
import ScaleLoader from "react-spinners/ScaleLoader";
import { postFeedback } from '../../../utils/api/dialogueApi';
import { readableServerError } from '../../../utils/utilFnx';
import SnackBar from 'my-react-snackbar';
import { getAuthToken } from '../../../utils/storage/auth';
import PromptToAuthenticate from '../../../components/uiComponents/promptToAuthenticate';


class Feedback extends Component {

    constructor(props) {
        super();
        this.state = {
            feedback: {
                subject: "",
                message: "",
                anonymous: false,
            },
            loading: false,
            snackMessage: { type: null, message: null },
        }
    }

    handleChange = (e) => {
        this.setState({
            feedback: { ...this.state.feedback, [e.target.name]: e.target.value, },
        });
    }

    handleCancel = () => {
        this.setState({
            feedback: {
                subject: "",
                message: "",
                anonymous: false,
            }
        })
    }

    handleSend = () => {
        this.setState({
            loading: true,
            snackMessage: { type: null, message: null },
        })

        postFeedback(this.state.feedback).then((data) => {

            this.handleCancel()
            this.setState({
                loading: false,
                snackMessage: { type: "success", message: "Feedback posted successfully! We really appreciate this." },

            })

        }).catch(err => {
            this.setState({
                loading: false,
                snackMessage: { type: "error", message: readableServerError(err) },

            })

        })
    }


    render = () => {

        return (
            <FeedbackPageContainer>
                <SnackBar
                    open={this.state.snackMessage.message}
                    message={this.state.snackMessage.message}
                    type={this.state.snackMessage.type}
                    position="bottom-right"
                    timeout={7000}
                />
                <div className="introHeader">
                    <HighlightedText margin="1em" color="#923D41">Feedback
                    </HighlightedText>
                    <HighlightedText margin="1em" color="#67809f" size="18px">
                        We build the best product for you through your feedback. Thank you in advance.
                    </HighlightedText>
                </div>
                {getAuthToken() ?
                    <Card shadow="0px 12px 30px #923D411A" style={{ borderLeft: "3px solid #923D41" }} padding="3em" margin="1em">
                        <Card margin="1em 1em">
                            <HighlightedText color="#923D41">Subject</HighlightedText>
                            <Input
                                type="text"
                                placeholder="Input the subject or topic of the feedback. Short and simple."
                                name="subject"
                                value={this.state.feedback.subject}
                                onChange={this.handleChange}
                                margin="25px 0"
                            />

                        </Card>
                        <Card margin="1em">
                            <HighlightedText color="#923D41">Message</HighlightedText>
                            <TextArea rows={6}
                                type="text"
                                placeholder="Enter the feedback message here. Be as verbose as you can. We are thrilled to read all."
                                name="message"
                                onChange={this.handleChange}
                                value={this.state.feedback.message}
                            ></TextArea>
                        </Card>

                        <HighlightedText color="black" size="12px" margin="10px 0 0" display="flex" alignItems="center">
                            <Input
                                width="1.5em"
                                height="1.5em"
                                margin="10px"
                                checked={this.state.feedback.anonymous}
                                type="checkbox"
                                placeholder="be anonymous"
                                name="anonymous"
                                onChange={(e) => this.setState({ feedback: { ...this.state.feedback, [e.target.name]: e.target.checked } })}

                            />
                            Be Anonymous
                        </HighlightedText>
                        <HighlightedText color="#923D41" size="12px" margin="10px">
                            If you feel uncomfortable letting us know your identity, feel free to remain anonymous. Regardless, we don't bite.
                        </HighlightedText>


                        {
                            this.state.loading ?
                                <ScaleLoader color="#D0021B" height="40px" radius="10px" margin="5px" loading={this.state.loading} size={20} />
                                :
                                <Card margin="2em 1em 1em">
                                    <Button background="ash" margin="1em" onClick={this.handleCancel}>
                                        <HighlightedText color="grey" size="2em">Cancel</HighlightedText>
                                    </Button>

                                    <Button margin="1em" onClick={this.handleSend}>
                                        <HighlightedText color="#fff" size="2em">Send</HighlightedText>
                                    </Button>

                                </Card>}

                    </Card>

                    : <PromptToAuthenticate />
                }
            </FeedbackPageContainer>

        );
    }

}

export default Feedback


const FeedbackPageContainer = styled.div`


`