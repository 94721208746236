


import React from 'react';
import styled from 'styled-components';
import { Card } from '../../../components/uiComponents/card';
import { HighlightedText } from '../../../components/uiComponents/highlightedText';
import { Input, TextArea, Radio } from "../../../components/uiComponents/input";
import { createQuizSummary, postAnswersWithSummaryID, retrieveQuiz } from '../../../utils/api/quizApi';
import { Button } from '../../../components/uiComponents/button';
import ExtraInfoCard from '../../../components/quiz/quizExtraInfoCard';
import { pruneReturnObject } from '../../../utils/utilFnx';
import { Prompt } from 'react-router';


class Exam extends React.Component {

    constructor(props) {
        super();
        this.state = {
            quiz: {},
            answers: [],
            quizSummary: {},
            rightAnswers: false,
            submittedQuiz: false,
        }
        this.onRefreshPrompt = e => {
            // the method that will be used for both add and remove event
            e.preventDefault();
            e.returnValue = '';
        }
    }

    componentDidMount = () => {
        // prompt the user they'll loose all saved work if refreshed

        if (!process.env.REACT_APP_DEBUG) {
            window.addEventListener("beforeunload", this.onRefreshPrompt);
        }

        if (this.props.quiz.id !== undefined) {
            this.setState({
                quiz: this.props.quiz,
            })
        } else {
            // console.log("pulling quiz data");
            this.getQuizData()
        }

        this.createQuizSummaryData()
    }

    componentWillUnmount = () => {
        if (!process.env.REACT_APP_DEBUG) {
            window.removeEventListener("beforeunload", this.onRefreshPrompt)
        }
    }


    createQuizSummaryData = () => {
        createQuizSummary(this.props.match.params.quizID).then((data) => {
            this.setState({
                quizSummary: data,
            })
        })

    }
    getQuizData = () => {
        retrieveQuiz(this.props.match.params.quizID).then((data) => {
            this.setState({
                quiz: data,
            })
        })

    }

    onAnswer = (e) => {
        // console.log(e.target.value);
        let answers = this.state.answers
        let picked = {}
        if (e.target.dataset.paid) {
            picked = {
                question: e.target.name,
                selected_answer: [e.target.value],
                input_answer: e.target.dataset.answer
            }
        } else if (e.target.dataset.essay) {
            picked = {
                question: e.target.name,
                input_answer: e.target.value
            }

        } else if (e.target.dataset.shortanswer) {
            picked = {
                question: e.target.name,
                input_answer: e.target.value
            }
        } else {
            picked = {
                question: e.target.name,
                input_answer: e.target.value
            }
        }
        answers[e.target.dataset.questionnumber] = picked
        this.setState({
            answers: answers
        })
        // console.log(this.state.answers);


    }

    onSubmit = () => {
        postAnswersWithSummaryID(
            pruneReturnObject(this.state.answers, 1),
            this.state.quizSummary.id).then(data => {
                this.setState({
                    submittedQuiz: true,
                })
                // console.log(data);

            }).catch(err => {
                console.log(err.response);
            })

    }
    render = () => {
        return (

            <ExamPageContainer>
                <Prompt
                    when={!this.state.submittedQuiz}
                    message={(location, action) => {
                            if (action !== "POP") {
                                return "You are attempting to leave this page. You may lose your work if not saved. Are you sure you want to do this?"
                            }
                        }
                    }
                />

                {/* titile */}

                {/* Instructions to hte quiz */}

                {/* Listing the questions. Questions is a combination of multiple chose and typed choice */}
                <div className="questionDivContainer">



                    <HighlightedText size="20px">
                        {this.state.quiz.instruction || "Answer all of the questions in this quiz"}
                    </HighlightedText>

                    {
                        this.state.quiz.questions?.map((question, i) =>
                            <Card key={i} margin="1em 0" padding="1em" id={`question-${i}`} radius="5px">
                                <Card display="flex" space="space-between">
                                    <HighlightedText color="grey" size="16px">
                                        Question {i + 1}
                                    </HighlightedText>
                                    <HighlightedText color="grey" size="14px">
                                        {`Points: ${question.points}`}
                                    </HighlightedText>


                                </Card>

                                <HighlightedText size="16px" weight="normal" lineHeight="1.5" margin="0.5em">
                                    {question.question}
                                </HighlightedText>
                                <Card display="flex">
                                    {(question.genre === "multiple-choice") ?
                                        <Radio width="100%">
                                            {
                                                question.possible_answers.map((pa, j) => {
                                                    return (
                                                        <label key={j} className="container">{pa.answer}
                                                            <Input
                                                                type="radio"
                                                                value={pa.id}
                                                                name={question.id}
                                                                required={false}
                                                                disabled={this.state.submittedQuiz}
                                                                onChange={this.onAnswer}
                                                                data-paid={pa.id} // possible answer ID
                                                                data-answer={pa.answer}
                                                                data-questionnumber={i}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    )
                                                })
                                            }
                                        </Radio>

                                        :
                                        (question.genre === "essay") ?
                                            <TextArea
                                                color="#000"
                                                width="80%"
                                                margin="2em"
                                                onChange={this.onAnswer}
                                                name={question.id}
                                                required={false}
                                                disabled={this.state.submittedQuiz}
                                                data-questionnumber={i}
                                                data-essay={true}
                                            />

                                            : (question.genre === "short-answer") ?
                                                <Input margin="2em"
                                                    onChange={this.onAnswer}
                                                    name={question.id}
                                                    required={false}
                                                    disabled={this.state.submittedQuiz}
                                                    data-questionnumber={i}
                                                    data-shortanswer={true}
                                                /> :
                                                <Input margin="2em"
                                                    onChange={this.onAnswer}
                                                    name={question.id}
                                                    required={false}
                                                    disabled={this.state.submittedQuiz}
                                                    data-questionnumber={i}
                                                    data-shortanswer={true}
                                                />}
                                    {/* <Input margin="2em" border="1px solid red" /> */}
                                    {/* <img src={WrongIcon} width="20px" /> */}
                                </Card>
                                <br />

                                {/* <Card>
                                <HighlightedText size="16px" weight="normal" lineHeight="1.5" margin="0.5em">
                                    The correct answer is that trevor noah has a big house.
                                </HighlightedText>
                            </Card> */}

                            </Card>

                        )
                    }

                    <Button
                        padding="20px 100px"
                        radius="5px"
                        display="block"
                        margin="1em auto"
                        color={this.state.submittedQuiz ? "rgba(142,68,173,1)" : ""}
                        background={this.state.submittedQuiz && "rgba(213,184,255,0.3)"}
                        disabled={this.state.submittedQuiz}
                        onClick={this.onSubmit}
                    >
                        {this.state.submittedQuiz ? "SUBMITTED" : "SUBMIT"}
                    </Button>
                </div >

                <ExtraInfoCard
                    questionLength={this.state.quiz.questions?.length}
                    answers={this.state.answers}
                    quiz={this.state.quiz}
                    quizSummary={this.state.quizSummary}
                    submittedQuiz={this.state.submittedQuiz}
                    topicID={this.props.match.params.topicID}
                    quizID={this.props.match.params.quizID}
                    updateUserSummaries={this.props.updateUserSummaries}
                />


            </ExamPageContainer >

        )
    }
}

export default Exam



const ExamPageContainer = styled.div`

    margin: 1em 1em 12vh;
    display: flex;

    .questionDivContainer{
        width: 80%;
        margin: 1em 1em 5em;
    }

    @media only screen and (max-width: 768px){
        flex-flow: wrap;
        .questionDivContainer{
            width: 100%;
        }
    }

    
`