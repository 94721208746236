import React, { Component } from 'react';
import { Card } from '../../components/uiComponents/card'
import { Button } from '../../components/uiComponents/button'
import { registerUser } from '../../utils/api/accountApi'
import styled from 'styled-components';
import { HighlightedText } from '../../components/uiComponents/highlightedText';
import { Input } from '../../components/uiComponents/input';
import { readableServerError, setItemToLocal } from '../../utils/utilFnx';
import ClipLoader from "react-spinners/ClipLoader";


class Register extends Component {


    constructor(props) {
        super(props);

        this.state = {
            email: "",
            first_name: "",
            last_name: "",
            organization: "",
            password: "",
            confirm_password: "",
            loading: false,
            errorMessage: null
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            errorMessage: null,
        });
    }


    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.password !== this.state.confirm_password) {
            this.setState({ errorMessage: "Password mismatch" })
            return
        }

        else {
            this.setState({ loading: true })
            registerUser(this.state)
                .then(data => {
                    // console.log(data);
                    this.setState({ loading: false })
                    setItemToLocal("email", this.state.email)
                    setItemToLocal("f_name", data.first_name)
                    setItemToLocal("l_name", data.last_name)
                    setItemToLocal("username", data.username)
                    setItemToLocal("u_id", data.id)
                    this.props.history.push('/auth/activate')
                })
                .catch(error => {
                    console.log(error.message)
                    this.setState({
                        loading: false,
                        errorMessage: readableServerError(error),
                    })
                });
        }

    }

    render() {

        return (
            <SignUpFormContainer>
                <HighlightedText textAlign="center" color="#923D41">
                    Sign Up to Skideo!
                </HighlightedText>
                <Card shadow="0px 12px 30px #923D411A" padding="1em 1em 1em">
                    <form onSubmit={this.handleSubmit}>
                        <Input placeholder="First Name" type="text" name="first_name" onChange={this.handleChange} required />
                        <Input placeholder="Last Name" type="text" name="last_name" onChange={this.handleChange} required />
                        <Input placeholder="Email" type="email" name="email" onChange={this.handleChange} required />
                        <Input className="bolder" placeholder="Password" type="password" name="password" onChange={this.handleChange} required />
                        <Input className="bolder" placeholder="Confirm Password" type="password" name="confirm_password" onChange={this.handleChange} required />
                        {this.state.errorMessage && <p>{this.state.errorMessage}</p>}
                        <Button
                            background="#923D41"
                            radius="30px"
                            margin="3em auto"
                            width="90%"
                            data-testid="signupbutton"
                            disabled={this.state.loading}
                        >
                            <ClipLoader color="#fff" loading={this.state.loading} size={10} />
                            {!this.state.loading && <span>Sign Up</span>}
                        </Button>
                    </form>
                </Card>
                <a href="/auth/login">Already have an account? Sign In</a>
            </SignUpFormContainer>

        );
    }
}
export default Register;


const SignUpFormContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:25%;

    input{
        border: 1px solid black;
        border-radius: 3px;
        display: block;
        padding: 10px 20px;
        margin: 25px auto;
        width: 80%;
        height: 1.5em;

        text-align: left;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        color: #923D41;
    }


    input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #923D41;
        font-weight: normal;
        opacity: 1; /* Firefox */
      }


    button{
        display: block;
        height: 40px;
    }

    a{
        text-decoration: none;
        color: #923D41;
        font-size: 12px;
        text-align: center;
        display: block;
        margin-top: 2em;
    }

    p{
        color: #923D41;
        text-align: center;
        margin: 0 auto;
        background: rgba(146, 61, 65, 0.1);
        width: fit-content;
        padding: 0.5em;
        border-radius: 0.5em
    }


    @media only screen and (max-width: 768px){
        display: block;
        margin: 20px auto;
        width:90%;

    }


`
