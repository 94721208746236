
import React, { Component } from 'react';
import { Card } from '../../components/uiComponents/card'
import { Button } from '../../components/uiComponents/button'
import styled from 'styled-components';
import { HighlightedText } from '../../components/uiComponents/highlightedText';
import { Input } from '../../components/uiComponents/input';
import { activateAccount } from '../../utils/api/accountApi';
import { getItemFromLocal, readableServerError, setItemToLocal } from '../../utils/utilFnx';
import ClipLoader from "react-spinners/ClipLoader";



class ActivateAccount extends Component {


    constructor(props) {
        super();

        this.state = {
            code: null,
            loading: false,
            errorMessage: null
        }
    }


    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        // console.log(this.state)
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true })
        activateAccount({ extra_data: `${getItemFromLocal("u_id")}-${this.state.code}` }).then(data => {
            console.log(data);
            this.setState({ loading: false })
            setItemToLocal("a_bearer", data.token)
            setItemToLocal("f_name", data.first_name)
            setItemToLocal("l_name", data.last_name)
            setItemToLocal("username", data.username)
            setItemToLocal("u_id", data.id)
            setItemToLocal("profile_pic", data.profile_pic) // set profile pic 

            this.props.history.push('/')
        }).catch(error => {

            const r_err = readableServerError(error)
            console.log(error.message)

            if (r_err === "Unexpected token u in JSON at position 0") {
                localStorage.removeItem("a_bearer")
                this.handleSubmit({ preventDefault: () => { } })
            }
            else {
                this.setState({
                    loading: false,
                    errorMessage: r_err,
                })

            }
        });

    }


    render() {

        return (
            <ActivateAccountEmailFormContainer>
                <HighlightedText textAlign="center" color="#923D41">
                    Verify Email
                </HighlightedText>
                <Card shadow="0px 12px 30px #923D411A" padding="2em 1em 1em">
                    <form onSubmit={this.handleSubmit}>
                        <Input placeholder="Short Code"
                            type="text"
                            name="code"
                            textAlign="center"
                            border="1px solid #55a630"
                            size="25px" onChange={this.handleChange} required />
                        <HighlightedText margin="5px auto"
                            background="#d8f3dc"
                            color="#000000cc"
                            padding="5px 10px"
                            radius="5px"
                            width="max-content"
                            size="12px"
                        >
                            Shortcode sent to email
                        </HighlightedText>
                        {this.state.errorMessage && <p>{this.state.errorMessage}</p>}
                        <Button background="#004b23" radius="30px" margin="5em auto" width="90%">
                            <ClipLoader color="#fff" loading={this.state.loading} size={10} />
                            {!this.state.loading && <span>ACTIVATE</span>}
                        </Button>
                    </form>
                </Card>
            </ActivateAccountEmailFormContainer>

        );
    }
}
export default ActivateAccount;




const ActivateAccountEmailFormContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:25%;


    input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #1b263b;
        font-weight: normal;
        opacity: 0.5; /* Firefox */
      }


    button{
        display: block;
        height: 40px;
    }

    a{
        text-decoration: none;
        color: #923D41;
        font-size: 12px;
        text-align: center;
        display: block;
        margin-top: 2em;
    }

    p{
        color: #923D41;
        text-align: center;
        margin: 0 auto;
        background: rgba(146, 61, 65, 0.1);
        width: fit-content;
        padding: 0.5em;
        border-radius: 0.5em
    }

    @media only screen and (max-width: 768px){
        display: block;
        margin: 20px auto;
        width:90%;
    }


`

