import React from 'react';
import styled from 'styled-components';
import { Card } from "../uiComponents/card"
import { HighlightedText } from "../uiComponents/highlightedText";



class LessonNotesBulletPoint extends React.Component {
    render() {
        return (
            <LessonNotesBulletPointContainer>
                <Card 
                 color="#923D41" 
                 padding="0 1em"
                  margin="0 0.5em"
                  >
                    <HighlightedText size="16px" weight="500" lineHeight="1.5">
                        {this.props.data}
                    </HighlightedText>

                </Card>

            </LessonNotesBulletPointContainer>
        )
    }
}

export default LessonNotesBulletPoint;

const LessonNotesBulletPointContainer = styled.span`
    padding: 0 1em;
    @media only screen and (max-width: 768px){
            
        }
`