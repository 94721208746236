
import React from 'react';
import styled from 'styled-components';
import ArrowRed from '../../assets/icons/arrow_right_red.svg'
import { Card } from '../uiComponents/card'
import { HighlightedText, HightlightedSpan } from '../uiComponents/highlightedText';
import { Link } from 'react-router-dom'
import { Button } from '../uiComponents/button';
import QuizIcon from '../../assets/icons/quiz.svg'
import VideoIcon from '../../assets/icons/video_wine.svg'
import NoteIcon from '../../assets/icons/notes-wine.svg'

import { durationReadableFromNumer } from '../../utils/utilFnx';
import { Collapse } from 'react-collapse';


class TopicSideBarSectionTile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapse: false,
        }
    }
    render = () => {
        // console.log(this.props);
        return (
            <div>
                <Card display="flex" space="space-between" margin="15px 15px 5px">
                    <HightlightedSpan size="12px" weight="bold" color="#923d41" margin="0.5em" display="block">
                        {this.props.section.title}
                    </HightlightedSpan>
                    <Button
                        background={this.state.collapse
                            ? "rgba(213, 184, 255, 0.3)"
                            : "rgba(232, 232, 232, 1)"
                        }
                        color={this.state.collapse
                            ? "rgba(142, 68, 173, 1)"
                            : "rgba(46, 49, 49, 1)"
                        }
                        radius="20px"
                        padding="5px 10px"
                        margin="0 5px 0"
                        height="25px"
                        onClick={() => {
                            this.setState({
                                collapse: !this.state.collapse
                            })
                        }
                        }
                    >
                        {this.state.collapse ? "Expand" : "Collapse"}
                    </Button>
                </Card>

                <Collapse isOpened={!this.state.collapse}>
                    {
                        this.props.section.syllabus_contents.map((syllabusContent, i) => {
                            // console.log(syllabusContent.content);
                            return <SyllabusContentContainer key={i}
                                to={{
                                    pathname: `/topic/${this.props.topicID}/main/${syllabusContent.content_type}/${syllabusContent.content?.id}`,
                                }}
                                completed={syllabusContent.content?.completed_by_user ? "completed" : undefined}
                                className={String(syllabusContent.content?.id) === this.props.contentID
                                    && (syllabusContent.content_type === this.props.contentType)
                                    && "activeContent"}
                                data-testid={`syllabuscontentlink-${i}`}
                            >
                                {
                                    // TODO: eliminate this class with & > div attribite
                                }
                                <div className="tabTile">
                                    <img
                                        src={syllabusContent.content_type === "quiz"
                                            ? QuizIcon :
                                            syllabusContent.content_type === "video"
                                            ? VideoIcon
                                            : NoteIcon}
                                        width="20px"
                                        height="30px"
                                        alt={syllabusContent.content_type || "content type"}
                                    />


                                    <HighlightedText size="15px" color="#923d41" margin="0.2em 0" width="75%">
                                        {syllabusContent.content?.title}
                                        <HightlightedSpan size="12px" weight="normal" color="#923d41" margin="0" display="block">
                                            {durationReadableFromNumer(syllabusContent.content?.duration)}
                                        </HightlightedSpan>
                                    </HighlightedText>
                                    <img src={ArrowRed} alt="link" />
                                </div>
                            </SyllabusContentContainer>
                        })
                    }
                </Collapse>
            </div>

        )
    }
}

export default TopicSideBarSectionTile




const SyllabusContentContainer = styled(Link)`

        
        text-decoration: none;
        

        .tabTile{ // TODO: eliminate this class with & > div attribite
            width: 80%;
            border-radius: 8px;
            border: ${({ completed }) => completed ? "none" : "2px solid #923d41"};
            background-color: ${({ completed }) => completed ? "rgba(0, 230, 64, 0.3)" : "#fff"};
            padding: 5px;
            margin: 10px 15px;
            display: flex;
            justify-content: space-around;

        }

        &.activeContent{
            background: rgba(232, 232, 232, 0.7);
            display: block;
            padding: 1px 0.5px;
            margin: 10px 5px;
            border-radius: 10px;
        }
        
`
