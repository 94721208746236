
import APIClient from './httpClient';



export const retrieveNote = async (noteID) => {
    const api = new APIClient();
    const endpoint = `/${noteID}/`;

    const { data } = await api.NoteApi.get(endpoint);
    return data;
}
