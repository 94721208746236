

import React from 'react';
import styled from 'styled-components';
import AuthenticateIllustration from '../../assets/illustrations/signin.svg'

class PromptToAuthenticate extends React.Component{

    render=()=>{
        return (
            <PromtContainer>
                    <img src={AuthenticateIllustration} alt="authenticate illustration" width="30%" height="30%"/>
                    <div className="message">
                        {this.props.message || "We cannot perform this action without authentication. Kindly login or register for to continue."}
                    </div>
                    
                    <div className="unauth_container">

                    <a className="login" href="/auth/login">
                        Login
                    </a>
                    <a className="signup" href="/auth/register">
                        Register
                    </a>

                    </div>

            </PromtContainer>
        )
    }
}

export default PromptToAuthenticate;

const PromtContainer = styled.div`
    width: 100%;
    display: block;
    margin: 3em auto 2em;
    text-align: -webkit-center;
    text-align: -moz-center;

    .message{
        background-color: rgba(213,184,255,0.3);
        color: rgba(142,68,173,1);
        width: 30%;
        padding: 1em;
        border-radius: 5px;
    }


    .unauth_container{
        margin: 2em 1em;
        align-self: center;
        display: flex;
        justify-content: center;

        .login{
            text-decoration: none;
            padding: 7px 15px;
            color: rgba(31, 58, 147, 1);
            background: rgba(137, 196, 244, 0.7);
            margin: 0 1em;
            display: block;
            border-radius: 1em;
        }
        .signup{
            text-decoration: none;

            padding: 7px 15px;
            color: rgba(30, 130, 76, 1);
            background: rgba(0, 230, 64, 0.3);
            /* margin-top: 19em; */
            display: block;
            border-radius: 1em;
        }
    }


    @media only screen and (max-width: 768px){
        .message{
            width: 80%;
        }
    }
    

`