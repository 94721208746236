import styled from 'styled-components';

export const Card = styled.div`
display: ${({ display }) => display || "block"};
color: ${({ color }) => color || "#ffffff"};
background-color: ${({ background }) => background || "#ffffff"};
border: ${({ border }) => border || 0};
padding: ${({ padding }) => padding || "0px 0px"};
margin: ${({ margin }) => margin || "0px 0px"};
border-radius: ${({ radius }) => radius || "2px"};
width: ${({ width }) => width || "auto"};
height: ${({ height }) => height || "auto"};
box-shadow: ${({ shadow }) => shadow || "none"};
justify-content: ${({ space }) => space || "normal"};
align-items: ${({ alignItems }) => alignItems || "normal"};
text-align: ${({ textAlign }) => textAlign};

flex-flow: ${({ flexFlow }) => flexFlow};


    &.withGradientBackground{
        
        background: ${({ background }) => background || "black"};  /* fallback for old browsers */
        background: -webkit-linear-gradient(${({ gradientBackground }) => gradientBackground});  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(${({ gradientBackground }) => gradientBackground}); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    }
    
    /* to use the hover, make sure you already have a border property set by default */
    &:hover{
        border-color: ${({ hoverBorderColor }) => hoverBorderColor || "#a93b3f"};
    }
    
`;
