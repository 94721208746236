import styled from "styled-components";

export const Input = styled.input`
    border: ${({ border }) => border || "1px solid black"};
    border-radius: ${({ radius }) => radius || "3px"};
    display: ${({ display }) => display || "block"};
    padding: ${({ padding }) => padding || "10px 20px"};
    margin: ${({ margin }) => margin || "25px auto"};
    width: ${({ width }) => width || "80%"};
    height: ${({ height }) => height || "1.5em"};
    

    text-align: ${({ textAlign }) => textAlign || "left"};;
    font-size: ${({ size }) => size || "14px"};
    text-transform: ${({ textTransform }) => textTransform || "none"};
    font-weight: ${({ weight }) => weight || "bold"};
    letter-spacing: 0;
    color: ${({ color }) => color || "#000"};

    &:focus {
        outline: none !important;
        border: ${({ borderFocus }) => borderFocus || "2px solid #923D41"};
    }
    
`

export const TextArea = styled.textarea`

    border: 1px solid black;
    border-radius: 5px;
    display: block;
    padding: 8px 15px;
    margin: ${({ margin }) => margin || "15px 2px"};
    width: ${({ width }) => width || "-webkit-fill-available"};
    width: ${({ width }) => width || "-moz-available"};
    text-align: left;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    color: ${({ color }) => color || "#923D41"};
    resize: vertical;

    &:focus {
        outline: none !important;
        border:2px solid #923D41;
        box-shadow: 0px 12px 30px #923D411A;
    }

    input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #923D41;
        font-weight: normal;
        opacity: 1; /* Firefox */
      }
    
      @media only screen and (max-width: 768px){
        
        
        ::placeholder{
            font-size: 12px;
        }
    }
`


export const Radio = styled.div`
        position: relative;
        display: flex;
        padding: 24px 0 0 ;
        flex-wrap: wrap;
        width: ${({ width }) => width};
        
        /* The container */
        .container {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 35px;
        margin-right: "50px";
        cursor: pointer;
        color: #717070;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        flex: 1 1 24%;
        margin-top: 10px;
        }
        
        /* Hide the browser's default radio button */
        .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        }
        
        /* Create a custom radio button */
        .checkmark {
        position: absolute;
        
        left: 0;
        height: 15px;
        width: 15px;
        border: 2px solid #717070;
        border-radius: 10px;
        }
        
        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
        background-color: #ccc;
        }
        
        /* When the radio button is checked, add a blue background */
        .container input:checked ~ .checkmark {
        background-color: #717070;
        }    
`;
